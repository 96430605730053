@import 'am-components/main.scss';
@import 'components/main.scss';
@import 'home.scss';
@import 'base/main.scss';

@font-face {
    font-display: block;
    font-family: Icomoon;
    font-style: normal;
    font-weight: 400;
    src: url(../../src/assets/fonts/Icomoon/Icomoon.woff?knrjde) format("woff"),url(../../src/assets/fonts/Icomoon/Icomoon.woff2?knrjde) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: DMSans Bold;
    font-style: normal;
    font-weight: 400;
    src: url(../../src/assets/fonts/DMSans/DMSans-Bold.woff) format("woff"),url(../../src/assets/fonts/DMSans/DMSans-Bold.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: DMSans Medium;
    font-style: normal;
    font-weight: 400;

    src: url(../../src/assets/fonts/DMSans/DMSans-Medium.woff) format("woff"),url(../../src/assets/fonts/DMSans/DMSans-Medium.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: DMSans Regular;
    font-style: normal;
    font-weight: 400;

    src: url(../../src/assets/fonts/DMSans/DMSans-Regular.woff) format("woff"),url(../../src/assets/fonts/DMSans/DMSans-Regular.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: HKGrotesk Regular;
    font-style: normal;
    font-weight: 400;

    src: url(../../src/assets/fonts/HKGrotesk/HKGrotesk-Regular.woff) format("woff"),url(../../src/assets/fonts/HKGrotesk/HKGrotesk-Regular.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: HKGrotesk Medium;
    font-style: normal;
    font-weight: 500;

    src: url(../../src/assets/fonts/HKGrotesk/HKGrotesk-Medium.woff2) format("woff2"),url(../../src/assets/fonts/HKGrotesk/HKGrotesk-Medium.woff) format("woff")
}
@font-face {
    font-display: swap;
    font-family: Poppins Medium;
    font-style:normal;
    font-weight: 500;

    src: url(../../src/assets/fonts/ionicons.woff) format("woff2"),
}

body.et_cover_background {
    background-size: cover!important;
    background-position: top!important;
    background-repeat: no-repeat!important;
    background-attachment: fixed;
}

body, input, textarea, select {
    font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
}

body {
    line-height: 1.5em;
}

body {
    font-family: Open Sans,Arial,sans-serif;
    font-size: 14px;
    color: #666;
    background-color: #fff;
    line-height: 1.7em;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a, abbr, acronym, address, applet, b, big, blockquote, body, center, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, tt, u, ul, var {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
    background: transparent;
    list-style: none;
}
h1, h2, h3, h4, h5, h6 {
    color: #171725;
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-weight: 400!important
}

body.overflow-hidden {
    overflow: hidden
}

a, a:hover {
    text-decoration: none;
}

a, a:hover {
    color: #c67b05;
}

img {
    max-width: 100%;
    height: auto;
}

#page-container {
    margin-top: 0!important;
    overflow: hidden
}

#main-content .container:before,#sidebar {
    display: none
}

#left-area {
    padding: 0 0 58px!important
}

@media (min-width: 981px) {
    #left-area {
        float:none!important;
        width: 100%!important
    }
}

.loading-indicator {
    text-align: center
}

.block-ui {
    min-height: 3em;
    position: relative
}

.block-ui-container {
    bottom: 0;
    height: 100%;
    left: 0;
    min-height: 2em;
    overflow: hidden!important;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1010
}

.block-ui-container:focus {
    outline: none
}

.block-ui-overlay {
    background-color: #b8b8b8;
    filter: alpha(opacity=50);
    height: 100%;
    opacity: .75;
    width: 100%
}

.block-ui-message-container {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10001
}

.block-ui-message {
    background: none;
    color: #333;
    margin-top: 9%;
    z-index: 1011
}

#indicator {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    height: 100px;
    left: calc(50% - 50px);
    position: absolute;
    top: calc(50% - 50px);
    width: 100px
}

#circle {
    fill: none;
    stroke: #c67b05;
    stroke-linecap: round;
    stroke-width: 4;
    -webkit-animation: draw 3s ease-in-out infinite;
    animation: draw 3s ease-in-out infinite
}

@-webkit-keyframes draw {
    0% {
        stroke-dasharray: 20,282.6
    }

    50% {
        stroke-dasharray: 200,282.6
    }

    to {
        stroke-dasharray: 20,282.6
    }
}

@keyframes draw {
    0% {
        stroke-dasharray: 20,282.6
    }

    50% {
        stroke-dasharray: 200,282.6
    }

    to {
        stroke-dasharray: 20,282.6
    }
}

@-webkit-keyframes spin {
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    to {
        transform: rotate(1turn)
    }
}

.hostedformButtonStyle {
    border: "none";
    font-family: DMSans Bold;
    line-height: 1;
    text-align: center;
    width: "200px"
}

.hostedformButtonStyle,.hostedformContainerStyle {
    align-items: "center";
    background-color: #c67b05;
    color: #fff;
    display: "inline-flex";
    flex-direction: "column";
    font-size: "18px";
    font-weight: "700";
    justify-content: "center";
    max-width: "100%";
    padding: "7px 16px"
}

.hostedformContainerStyle {
    border: "2px solid #c67b05";
    border-radius: "21px";
    font-family: DM Sans Bold;
    line-height: 1.3;
    min-height: "42px";
    text-align: "center";
    width: "296px"
}

.ccolinks {
    align-items: center!important;
    background-color: transparent!important;
    border: none!important;
    border-radius: 0!important;
    color: #c67b05!important;
    display: inline-flex!important;
    font-size: 12px!important;
    height: 0!important;
    line-height: 0px!important;
    margin: 0!important
}

.custom-post-breadcrumb .lms-breadcrumbs,.custom-post-breadcrumb .lms-breadcrumbs .breadcrumb-trail {
    display: inline
}

.custom-post-breadcrumb .lms-breadcrumbs .breadcrumb-trail>span:nth-child(-n+2),.custom-post-breadcrumb .lms-breadcrumbs>span:nth-child(-n+2) {
    display: none
}

.radio-button-item {
    align-items: center;
    box-shadow: none!important;
    display: grid;
    justify-content: space-between;
    margin-bottom: 5px
}

.description {
    margin-right: 10px
}

.radio-buttons input:not([type=file]):not([type=checkbox]) {
    box-shadow: none!important;
    display: flex;
    transform: scale(1.1)
}

.radio-buttons-yes {
    margin-right: 10px
}

.radio-buttons-no,.radio-buttons-yes {
    text-align: center!important
}

.radio-buttons-no {
    margin-left: 10px
}

[class*=" cco_icon_"],[class^=cco_icon_] {
    font-feature-settings: normal;
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #666;
    font-family: Icomoon!important;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none
}

.cco_icon_arrow_up_1:before {
    content: "\e915"
}

.cco_icon_arrow_down_1:before {
    content: "\e916"
}

.cco_icon_arrow_right_1:before {
    content: "\e906"
}

.cco_icon_avatar_1:before {
    content: "\e910"
}

.cco_icon_avatar_2:before {
    content: "\e91a"
}

.cco_icon_badge_1_path_0:before {
    content: "\e92d"
}

.cco_icon_badge_1_path_1:before {
    content: "\e92e"
}

.cco_icon_camera_1_path_0:before {
    content: "\e91b"
}

.cco_icon_camera_1_path_1:before {
    content: "\e91c";
    margin-left: -1em
}

.cco_icon_camera_1_path_2:before {
    content: "\e91d";
    margin-left: -1em
}

.cco_icon_camera_2:before {
    content: "\e92c"
}

.cco_icon_caret_down_1:before {
    content: "\e912"
}

.cco_icon_caret_down_2:before {
    content: "\e911"
}

.cco_icon_caret_left_1:before {
    content: "\e927"
}

.cco_icon_caret_left_2:before {
    content: "\e929"
}

.cco_icon_caret_right_1:before {
    content: "\e900"
}

.cco_icon_caret_right_2:before {
    content: "\e928"
}

.cco_icon_cart_1:before {
    content: "\e901"
}

.cco_icon_certificate_1:before {
    content: "\e90a"
}

.cco_icon_chatting_1:before {
    content: "\e907"
}

.cco_icon_check_1:before {
    content: "\e90d"
}

.cco_icon_check_2:before {
    content: "\e90c"
}

.cco_icon_check_3_path_0:before {
    content: "\e91e"
}

.cco_icon_check_3_path_1:before {
    content: "\e91f";
    margin-left: -1em
}

.cco_icon_check_4:before {
    content: "\e920"
}

.cco_icon_click_1:before {
    content: "\e902"
}

.cco_icon_close_1:before {
    content: "\e919"
}

.cco_icon_crane_1:before {
    content: "\e903"
}

.cco_icon_dashboard_1:before {
    content: "\e90b"
}

.cco_icon_driver_license_1:before {
    content: "\e921"
}

.cco_icon_ellipsis_1:before {
    content: "\e905"
}

.cco_icon_gear_1:before {
    content: "\e90f"
}

.cco_icon_loader_1_path_0:before {
    content: "\e922"
}

.cco_icon_loader_1_path_1:before {
    content: "\e923";
    margin-left: -1em
}

.cco_icon_loader_1_path_2:before {
    content: "\e924";
    margin-left: -1em
}

.cco_icon_loader_1_path_3:before {
    content: "\e925";
    margin-left: -1em
}

.cco_icon_loader_1_path_4:before {
    content: "\e926";
    margin-left: -1em
}

.cco_icon_logout_1:before {
    content: "\e90e"
}

.cco_icon_loupe_1:before {
    content: "\e914"
}

.cco_icon_loupe_2:before {
    content: "\e913"
}

.cco_icon_megaphone_1:before {
    content: "\e917"
}

.cco_icon_menu_1:before {
    content: "\e918"
}

.cco_icon_paper_1:before {
    content: "\e909"
}

.cco_icon_paper_plane_1:before {
    content: "\e908"
}

.cco_icon_plus_1:before {
    content: "\e904"
}

.cco_icon_external_1:before {
    content: "\e92a"
}

.cco_icon_file_1:before {
    content: "\e92b"
}

.et_builder_inner_content .cco_et_section_0 {
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0
}

.et_builder_inner_content .cco_et_section_0>.et_pb_row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    max-width: 1360px;
    min-height: 100vh;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 150px;
    position: static;
    width: 100%
}

@media (min-width: 981px) and (max-width:1199px) {
    .et_builder_inner_content .cco_et_section_0>.et_pb_row {
        padding-top:180px
    }
}

@media (max-width: 767px) {
    .et_builder_inner_content .cco_et_section_0>.et_pb_row {
        padding-top:130px
    }
}

.et_builder_inner_content .cco_et_row_0 {
    padding-bottom: 40px;
    padding-top: 0
}

.et_builder_inner_content .cco_et_row_1 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12.5px;
    margin-right: -12.5px;
    padding-bottom: 0;
    padding-top: 0
}

.et_builder_inner_content .cco_et_col_0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    padding-bottom: 70px;
    padding-right: 2.75%;
    padding-top: 50px;
    width: 50%;
    z-index: 100
}

@media (max-width: 1199px) {
    .et_builder_inner_content .cco_et_col_0 {
        margin-bottom:20px;
        padding-bottom: 0;
        padding-right: 0;
        padding-top: 0;
        width: 100%
    }
}

@media (max-width: 980px) {
    .et_builder_inner_content .cco_et_col_0 {
        display:block
    }
}

.et_builder_inner_content .cco_et_col_1 {
    padding-bottom: 0;
    padding-top: 0;
    position: static;
    width: 50%
}

@media (max-width: 1199px) {
    .et_builder_inner_content .cco_et_col_1 {
        margin-top:auto;
        width: 100%
    }
}

.et_builder_inner_content .cco_et_col_2,.et_builder_inner_content .cco_et_col_3 {
    margin-bottom: 30px!important;
    margin-right: 0!important;
    padding-left: 12.5px;
    padding-right: 12.5px;
    width: auto!important
}

.et_builder_inner_content .cco_et_text_0,.et_builder_inner_content .cco_et_text_0 h1,.et_builder_inner_content .cco_et_text_0 h2,.et_builder_inner_content .cco_et_text_0 h3,.et_builder_inner_content .cco_et_text_0 h4,.et_builder_inner_content .cco_et_text_0 h5,.et_builder_inner_content .cco_et_text_0 h6 {
    color: #fff!important
}

.et_builder_inner_content .cco_et_text_0 h1 {
    font-size: 72px
}

.et_builder_inner_content .cco_et_text_0 h2 {
    font-size: 20px;
    line-height: 1.5!important
}

.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h1,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h2,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h3,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h4,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h5,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h6 {
    padding-bottom: 30px
}

@media (max-width: 980px) {
    .et_builder_inner_content .cco_et_text_0 h1 {
        font-size:56px
    }
}

@media (max-width: 767px) {
    .et_builder_inner_content .cco_et_text_0 h1 {
        font-size:40px
    }
}

.et_builder_inner_content .cco_et_img_0 .et_pb_image_wrap,.et_builder_inner_content .cco_et_img_0 img {
    width: 100%
}

@media (min-width: 1200px) {
    .et_builder_inner_content .cco_et_img_0 {
        height:100%;
        left: 50%;
        position: absolute;
        top: 50px;
        width: 50%
    }

    .et_builder_inner_content .cco_et_img_0 .et_pb_image_wrap {
        height: 100%
    }

    .et_builder_inner_content .cco_et_img_0 .et_pb_image_wrap img {
        height: 100%;
        max-width: 1280px;
        -o-object-fit: contain;
        object-fit: contain
    }
}

@media (max-width: 1199px) {
    .et_builder_inner_content .cco_et_img_0 {
        margin-left:-40px;
        margin-right: -40px
    }
}


.cco_section_2 {
    background-color: #fff;
    border-bottom: 1px solid #e2e2ea;
    padding: 5px 40px;
    position: relative;
    z-index: 100
}

@media (max-width: 767px) {
    .cco_section_2 {
        display:none
    }
}

.cco_section_3 {
    margin-left: 250px;
    margin-right: auto;
    max-width: 1350px;
    padding: 40px 40px 17.5px
}

@media (min-width: 1851px) {
    .cco_section_3 {
        margin-left:auto
    }
}

@media (max-width: 1199px) {
    .cco_section_3 {
        margin-left:auto
    }
}

@media (max-width: 767px) {
    .cco_section_3 {
        padding-bottom:15px;
        padding-left: 25px;
        padding-right: 25px
    }
}

.cco_section_4 {
    margin-left: 250px;
    margin-right: auto;
    max-width: 1350px;
    padding: 17.5px 40px 40px
}

@media (min-width: 1851px) {
    .cco_section_4 {
        margin-left:auto
    }
}

@media (max-width: 1199px) {
    .cco_section_4 {
        margin-left:auto
    }
}

@media (max-width: 767px) {
    .cco_section_4 {
        padding-left:25px;
        padding-right: 25px;
        padding-top: 15px
    }
}




.cco_row_0 {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    display: flex;
    margin-top: 80px;
    padding: 25px 40px 30px
}

@media (max-width: 980px) {
    .cco_row_0 {
        margin-top:0
    }
}

@media (max-width: 767px) {
    .cco_row_0 {
        background-color:transparent;
        border-radius: 0;
        box-shadow: none;
        display: block;
        padding: 0
    }
}

@media (min-width: 768px) {
    .cco_row_1 {
        margin-bottom:35px
    }
}

.cco_row_2 {
    margin-bottom: 20px
}

.cco_row_3 {
    border: 1px solid #e2e2ea;
    border-radius: 23px;
    padding: 20px 25px 25px
}

@media (min-width: 768px) {
    .cco_row_3 {
        margin-bottom:35px
    }
}

@media (max-width: 767px) {
    .cco_row_4 {
        display:none
    }
}

.cco_row_5 {
    display: flex;
    margin-bottom: 0
}

.cco_row_6 {
    border: 1px solid #e2e2ea;
    border-radius: 23px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 25px 25px
}

@media (min-width: 768px) {
    .cco_row_6 {
        margin-bottom:35px
    }
}

.cco_row_7 {
    display: flex;
    // flex-wrap: wrap;
    margin-bottom: 20px
}

.cco_row_8 {
    margin-bottom: 40px
}

.cco_row_9 {
    margin-bottom: 40px
}

.cco_row_10,.cco_row_9 {
    border: 1px solid #e2e2ea;
    border-radius: 23px;
    padding: 20px 25px 25px
}

.cco_row_10 {
    margin-bottom: 20px
}

.cco_row_11 {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    padding: 25px 40px 30px
}

@media (max-width: 767px) {
    .cco_row_11 {
        padding-left:30px;
        padding-right: 30px
    }
}

@media (min-width: 768px) {
    .cco_row_11 {
        margin-bottom:35px
    }
}


.cco_col_0 {
    padding-right: 20px;
    width: 30%
}

.cco_col_0,.cco_col_1 {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.cco_col_1 {
    padding-left: 20px;
    width: 70%
}

.cco_col_2 {
    padding-right: 15px
}

.cco_col_2,.cco_col_3 {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.cco_col_3 {
    flex-grow: 1
}

.cco_col_4 {
    padding-right: 15px
}

@media (max-width: 767px) {
    .cco_col_4 {
        margin-bottom:20px;
        padding-right: 0
    }
}

.cco_col_5 {
    padding-left: 15px
}

@media (max-width: 767px) {
    .cco_col_5 {
        padding-left:0
    }
}

.cco_col_6 {
    width: 100%
}

.cco_col_6:nth-last-child(n+2) {
    padding-right: 12.5px;
    width: 50%
}

@media (max-width: 980px) {
    .cco_col_6:nth-last-child(n+2) {
        margin-bottom:25px;
        padding-right: 0;
        width: 100%
    }
}

.cco_col_7 {
    padding-left: 12.5px;
    width: 50%
}

@media (max-width: 980px) {
    .cco_col_7 {
        padding-left:0;
        width: 100%
    }
}

.cco_col_8 {
    width: calc(33.33% - 16.66px)
}

.cco_col_8:nth-last-child(n+2) {
    margin-right: 25px
}

@media (max-width: 980px) {
    .cco_col_8 {
        width:calc(50% - 12.5px)
    }

    .cco_col_8:nth-last-child(n+2) {
        margin-bottom: 25px
    }

    .cco_col_8:nth-child(2n) {
        margin-right: 0
    }
}

@media (max-width: 767px) {
    .cco_col_8 {
        width:100%
    }

    .cco_col_8:nth-last-child(n+2) {
        margin-bottom: 25px;
        margin-right: 0
    }
}

.cco_col_9 {
    display: none;
    flex-direction: column;
    justify-content: center;
    max-width: 30%;
    padding-right: 20px
}

@media (max-width: 1199px) {
    .cco_col_9 {
        display:flex
    }
}

.cco_col_10 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
    width: 30%
}

@media (max-width: 1199px) {
    .cco_col_10 {
        padding-left:20px
    }
}

.cco_col_11 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    max-width: 70%;
    padding-left: 20px
}

.cco_col_12 {
    width: 100%
}



.cco_text_0 {
    color: #92929d;
    font-family: HKGrotesk Regular;
    font-size: 18px
}

.cco_text_0 strong {
    color: #171725;
    font-family: DMSans Bold;
    font-size: 24px;
    line-height: 1.3
}

.cco_text_1 {
    margin-bottom: 10px
}

.cco_text_1 strong {
    color: #000;
    font-family: DMSans Medium;
    font-weight: 500
}

.cco_text_1 h1 {
    font-size: 32px
}

.cco_text_1 h1 small {
    color: #666;
    display: inline-block;
    font-family: DMSans Regular;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    top: -4px
}

.cco_text_1 .divider {
    margin-left: 20px
}

.cco_text_2:nth-last-child(n+2) {
    margin-bottom: 30px
}

.cco_text_2 h2 {
    font-size: 20px
}

.cco_text_2 h2 small {
    color: #666;
    display: inline-block;
    font-family: DMSans Regular;
    font-size: 14px;
    font-weight: 400
}

.cco_text_3 {
    font-family: HKGrotesk Regular;
    font-size: 14px;
    line-height: 1.3
}

.cco_text_3 h3 {
    font-family: DMSans Medium;
    font-size: 16px;
    font-weight: 500
}

.cco_text_3 .success {
    color: #3dd598
}

.cco_text_3 .error {
    color: #db3a3a
}

.cco_text_4 {
    background-color: #fafafb;
    font-family: DMSans Medium;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.4;
    padding: 10px 15px;
    text-transform: uppercase
}

.cco_text_4:nth-child(n+2) {
    border-top: 1px solid #e2e2ea
}

.cco_text_5 {
    margin-bottom: 40px
}

.cco_text_5 h1 {
    font-size: 24px
}

.cco_text_6 {
    font-size: 18px;
    margin-bottom: 10px
}

.cco_text_7 {
    font-size: 10px
}

.cco_text_8 {
    font-family: DMSans Medium;
    font-weight: 500;
    margin-bottom: 20px
}

.cco_text_9 {
    font-size: 14px
}

.cco_text_9 h3 {
    font-size: 18px
}

.cco_text_10 {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center
}

.cco_text_10 h2 {
    font-size: 20px
}

.cco_text_10 h2 small {
    color: #666;
    display: inline-block;
    font-family: DMSans Regular;
    font-size: 14px;
    font-weight: 400
}

.cco_text_11 {
    color: #171725;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3
}


strong {
    font-weight: 700;
}



.cco_text_13 h1 {
    color: #171725;
    font-size: 32px
}

.cco_text_14 {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    padding: 25px 20px 30px
}

.cco_text_15 {
    color: #171725
}

.cco_table {
    font-size: 14px;
    line-height: 1.6
}

.cco_table,.cco_table_body {
    display: block
}

.cco_table_row {
    display: flex;
    margin-left: -20px;
    margin-right: -20px
}

.cco_table_cell {
    padding: 0 20px;
    width: 33.33%
}

.cco_table_cell_small {
    width: 30%
}

.cco_table_cell_medium {
    width: 40%
}

.cco_table_cell_large {
    width: 66.66%
}

@media (max-width: 980px) {
    .cco_table_row {
        flex-direction:column;
        margin-left: 0;
        margin-right: 0
    }

    .cco_table_cell {
        padding-left: 0;
        padding-right: 0;
        width: 100%
    }

    .cco_table_cell_large {
        width: 100%
    }
}

.cco_table_0 .cco_table_row {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0
}

.cco_table_0 .cco_table_cell {
    padding-left: 0;
    padding-right: 0;
    width: 100%
}

.cco_table_0 .cco_table_cell:nth-last-child(n+2) {
    margin-bottom: 22px
}

.cco_table_0 .cco_table_cell_large {
    width: 100%
}

.cco_table_1:nth-last-child(n+2) {
    border-bottom: 1px solid #e2e2ea;
    margin-bottom: 20px;
    padding-bottom: 20px
}

.cco_blurb_0 {
    display: flex;
    padding-bottom: 8px;
    padding-top: 8px
}

.cco_blurb_0 strong {
    color: #000;
    font-family: DMSans Medium;
    font-weight: 500
}

.cco_blurb_0 .cco_blurb_label {
    min-width: 100px;
    padding-right: 20px
}

.cco_blurb_0 .cco_blurb_value {
    min-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis
}

.cco_blurb_0.cco_blurb_has_border {
    border-bottom: 1px solid #e4e4e2
}

.cco_blurb_1 {
    display: flex;
    font-size: 14px
}

.cco_blurb_1:nth-last-child(n+2) {
    margin-bottom: 12px
}

.cco_blurb_1 strong {
    color: #000;
    font-family: DMSans Medium;
    font-weight: 500
}

.cco_blurb_1 .cco_blurb_label {
    padding-right: 10px;
    width: 33.33%
}

.cco_blurb_1 .cco_blurb_value {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 66.66%
}

.cco_blurb_2 {
    display: flex
}

.cco_blurb_2 .cco_blurb_img {
    background-color: #fff;
    border-radius: 23px;
    height: 120px;
    margin-right: 20px;
    min-width: 120px;
    overflow: hidden;
    position: relative;
    width: 120px
}

.cco_blurb_2 .cco_blurb_img:before {
    border: 1px dashed #aeaeae;
    border-radius: 23px;
    content: "";
    height: calc(100% - 2px);
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 2px)
}

.cco_blurb_2 .cco_blurb_img img {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
}

.cco_blurb_2 .cco_blurb_img_icon {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #aeaeae;
    font-family: Icomoon;
    font-size: 40px;
    font-style: normal;
    left: calc(50% - 20px);
    line-height: 1;
    position: absolute;
    top: calc(50% - 20px)
}

.cco_blurb_2 .cco_blurb_img_icon_camera:before {
    content: "\e92c"
}

.cco_blurb_2 .cco_blurb_img_icon_file:before {
    content: "\e92b"
}

.cco_blurb_2 .cco_blurb_title {
    color: #000;
    font-size: 14px;
    padding-top: 10px;
    word-break: break-all
}

.cco_blurb_3 {
    color: #000;
    display: flex;
    font-family: DMSans Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3
}

.cco_blurb_3:nth-child(n+2) {
    margin-top: 12px
}

.cco_blurb_3:before {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Icomoon;
    font-style: normal;
    line-height: 14px;
    min-width: 17px;
    padding-right: 8px;
    padding-top: 2px
}

.cco_blurb_3.cco_blurb_success:before {
    color: #3dd598;
    content: "\e920";
    font-size: 17px
}

.cco_blurb_3.cco_blurb_error:before {
    color: #db3a3a;
    content: "\e919";
    font-size: 12px
}

.cco_blurb_4 {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    color: #000;
    display: flex;
    font-family: DMSans Medium;
    font-size: 16px;
    font-weight: 500;
    padding: 20px
}

.cco_blurb_4 .cco_blurb_main {
    flex-grow: 1;
    padding-right: 40px
}

.cco_blurb_4 .cco_blurb_aside {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.cco_blurb_5 {
    display: flex;
    justify-content: flex-end;
    min-width: 80px
}

.cco_blurb_5:after {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3dd598;
    content: "\e920";
    font-family: Icomoon;
    font-size: 17px;
    font-style: normal;
    line-height: 14px;
    min-width: 17px;
    padding-left: 8px;
    padding-top: 2px
}

.cco_menu_0 {
    display: flex;
    margin-left: -9px;
    margin-right: -9px;
    list-style: none;

}


.cco_menu_0 .cco_menu_item {
    margin-bottom: 15px;
    padding-left: 9px;
    padding-right: 9px
}

.cco_menu_item  a{
text-decoration: none;
}

.cco_menu_0 .cco_menu_item a {
    align-items: center;
    background-color: #fff;
    border: 1px solid #c67b05;
    border-radius: 21px;
    color: #c67b05;
    display: flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    line-height: 1.3;
    min-height: 42px;
    padding: 8px 29px;
    text-align: center;
}

.cco_menu_0 .cco_menu_item_primary {
    flex-grow: 1
}

.cco_menu_0 .cco_menu_item_primary a {
    background-color: #c67b05;
    color: #fff
}

@media (max-width: 1199px) {
    .cco_menu_0 .cco_menu_item a {
        font-size:16px
    }
}

@media (max-width: 980px) {
    .cco_menu_0 {
        flex-wrap:wrap
    }

    .cco_menu_0 .cco_menu_item {
        width: 50%
    }
}

@media (max-width: 767px) {
    .cco_menu_0 {
        margin-left:0;
        margin-right: 0
    }

    .cco_menu_0 .cco_menu_item {
        padding-left: 0;
        padding-right: 0;
        width: 100%
    }

    .cco_menu_0 .cco_menu_item:last-child {
        margin-bottom: 0
    }
}

.cco_dropdown_content ul {
margin-bottom: 0px !important;
    list-style: none !important;
    padding-left: 0px !important;
    margin-top: 0px;
}

.cco_menu_1 .cco_menu_item a {
    border-top: 1px solid #e2e2ea;
    color: #000;
    display: block;
    font-size: 13px;
    line-height: 1.3;
    padding: 10px 15px 10px 37px;
    position: relative;
    text-decoration: none;
}

.cco_menu_1 .cco_menu_item a:before {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #666;
    content: "\e906";
    font-family: Icomoon;
    font-size: 14px;
    font-style: normal;
    left: 15px;
    line-height: 1;
    position: absolute;
    top: calc(50% - 7px)
}

.cco_menu_1 .cco_menu_item a:hover {
    background-color: #fefcf4
}

.cco_menu_1 .cco_menu_item.cco_menu_item_icon_gear a:before {
    content: "\e90f"
}

.cco_menu_1 .cco_menu_item.cco_menu_item_icon_logout a:before {
    content: "\e90e"
}

.cco_menu_1 .cco_menu_item.cco_menu_item_icon_avatar a:before {
    content: "\e910"
}

.cco_menu_1:first-child .cco_menu_item:first-child a {
    border-top: 0
}

.cco_menu_2 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px
}

.cco_menu_2 .cco_menu_item {
    margin-bottom: 5px
}

.cco_menu_2 .cco_menu_item:nth-last-child(n+2) {
    margin-right: 10px
}

.cco_menu_2 .cco_menu_item a {
    align-items: center;
    background-color: #c67b05;
    border: 1px solid #c67b05;
    border-radius: 5px;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    line-height: 1.3;
    min-height: 26px;
    min-width: 70px;
    padding: 2px 6px;
    text-align: center;
    white-space: nowrap
}

.cco_menu_3 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
    margin-left: -9px;
    margin-right: -9px
}

.cco_menu_3 .cco_menu_item {
    margin-bottom: 15px;
    padding-left: 9px;
    padding-right: 9px
}

.cco_menu_3 .cco_menu_item a {
    align-items: center;
    background-color: #c67b05;
    border: 1px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    line-height: 1.3;
    min-height: 42px;
    min-width: 166px;
    padding: 8px 17px;
    text-align: center
}

.cco_menu_3 .cco_menu_item:first-child a {
    background-color: #fff;
    color: #c67b05
}

@media (max-width: 1199px) {
    .cco_menu_3 .cco_menu_item a {
        font-size:16px
    }
}

@media (max-width: 767px) {
    .cco_menu_3 {
        margin-bottom:0;
        margin-left: 0;
        margin-right: 0
    }

    .cco_menu_3 .cco_menu_item {
        padding-left: 0;
        padding-right: 0;
        width: 100%
    }

    .cco_menu_3 .cco_menu_item a {
        min-width: 0
    }

    .cco_menu_3 .cco_menu_item:last-child {
        margin-bottom: 0
    }
}

.cco_menu_4 {
    border: 1px dashed #e2e2ea;
    border-radius: 20px;
    display: flex;
    padding: 17px 11px
}

.cco_menu_4 .cco_menu_item {
    flex-grow: 1;
    padding-left: 9px;
    padding-right: 9px
}

.cco_menu_4 .cco_menu_item a {
    align-items: center;
    color: #666;
    display: inline-flex;
    line-height: 1.3;
    min-height: 38px;
    padding: 3px 20px
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon a {
    position: relative
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon a:before {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    border-radius: 50%;
    color: #c67b05;
    font-family: Icomoon;
    font-size: 15px;
    font-style: normal;
    line-height: 1;
    padding: 11.5px;
    position: absolute;
    top: calc(50% - 19px)
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon_plus a:before {
    content: "\e904"
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon_close a:before {
    content: "\e919"
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon_caret_left a:before {
    content: "\e929"
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon_caret_right a:before {
    content: "\e928"
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon_checkmark a:before {
    content: "\e920"
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon:first-child a,.cco_menu_4 .cco_menu_item.cco_menu_item_icon:nth-last-child(n+2) a {
    padding-left: 46px
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon:first-child a:before,.cco_menu_4 .cco_menu_item.cco_menu_item_icon:nth-last-child(n+2) a:before {
    left: 0
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon:nth-child(n+2) {
    text-align: center
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child {
    text-align: right
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child a {
    padding-right: 46px
}

.cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child a:before {
    right: 0
}

.cco_menu_4 .cco_menu_item.cco_menu_item_btn a {
    align-items: center;
    background-color: #c67b05;
    border: 2px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    display: inline-flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    line-height: 1.3;
    max-width: 100%;
    min-height: 42px;
    padding: 7px 16px;
    text-align: center;
    width: 296px
}

.cco_menu_4 .cco_menu_item.cco_menu_item_btn:last-child {
    text-align: right
}

.cco_menu_4 .cco_menu_item.cco_menu_item_btn_disabled a {
    cursor: not-allowed;
    opacity: .5
}

@media (max-width: 1199px) {
    .cco_menu_4 .cco_menu_item.cco_menu_item_btn a {
        font-size:16px
    }
}

@media (max-width: 767px) {
    .cco_menu_4 {
        flex-wrap:wrap;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0
    }

    .cco_menu_4 .cco_menu_item {
        padding-left: 0;
        padding-right: 0;
        width: 100%
    }

    .cco_menu_4 .cco_menu_item:nth-last-child(n+2) {
        margin-bottom: 15px
    }

    .cco_menu_4 .cco_menu_item a {
        font-size: 14px
    }

    .cco_menu_4 .cco_menu_item.cco_menu_item_icon a:before {
        box-shadow: 0 10px 30px rgba(0,0,0,.05)
    }

    .cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child,.cco_menu_4 .cco_menu_item.cco_menu_item_icon:nth-child(n+2) {
        text-align: left
    }

    .cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child a {
        padding-left: 46px;
        padding-right: 20px
    }

    .cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child a:before {
        left: 0;
        right: auto
    }

    .cco_menu_4 .cco_menu_item.cco_menu_item_btn a {
        width: 100%
    }
}

.cco_menu_5 .cco_menu_item:nth-last-child(n+2) {
    margin-bottom: 20px;
  
    
}
.cco_menu_5 .cco_menu_item {
list-style: none;

}


.cco_menu_5 .cco_menu_item.cco_menu_item_active a {
    color: #c67b05;
    font-family: DMSans Bold;
    font-weight: 700
}

.cco_menu_5 .cco_menu_item.cco_menu_item_active a:after {
    background-color: #c67b05
}

.cco_menu_5 .cco_menu_item.cco_menu_item_icon_dashboard a:before {
    content: "\e90b"
}

.cco_menu_5 .cco_menu_item.cco_menu_item_icon_certificate a:before {
    content: "\e90a"
}

.cco_menu_5 .cco_menu_item.cco_menu_item_icon_paper a:before {
    content: "\e909"
}

.cco_menu_5 .cco_menu_item.cco_menu_item_icon_paper_plane a:before {
    content: "\e908"
}

.cco_menu_5 .cco_menu_item.cco_menu_item_icon_chatting a:before {
    content: "\e907"
}



.cco_menu_6 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
    margin-left: -9px;
    margin-right: -9px
}

.cco_menu_6 .cco_menu_item {
    margin-bottom: 15px;
    padding-left: 9px;
    padding-right: 9px;
    width: 50%
}

.cco_menu_6 .cco_menu_item:first-child:last-child {
    width: 100%
}

.cco_menu_6 .cco_menu_item a {
    align-items: center;
    background-color: #c67b05;
    border: 1px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    line-height: 1.3;
    min-height: 42px;
    padding: 8px 17px;
    text-align: center
}

.cco_menu_6 .cco_menu_item:not(:last-child):nth-child(odd) a {
    background-color: #fff;
    color: #c67b05
}

@media (max-width: 1199px) {
    .cco_menu_6 .cco_menu_item a {
        font-size:16px
    }
}

@media (max-width: 479px) {
    .cco_menu_6 {
        margin-bottom:0;
        margin-left: 0;
        margin-right: 0
    }

    .cco_menu_6 .cco_menu_item {
        padding-left: 0;
        padding-right: 0;
        width: 100%
    }

    .cco_menu_6 .cco_menu_item:last-child {
        margin-bottom: 0
    }
}

.cco_menu_7 {
    display: flex;
    margin-left: -9px;
    margin-right: -9px
}

.cco_menu_7 .cco_menu_item {
    padding-left: 9px;
    padding-right: 9px
}

.cco_menu_7 .cco_menu_item a {
    align-items: center;
    background-color: #fff;
    border: 2px solid #c67b05;
    border-radius: 21px;
    color: #c67b05;
    display: flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    line-height: 1.3;
    max-width: 100%;
    min-height: 42px;
    padding: 7px 16px;
    text-align: center;
    width: 296px
}

@media (max-width: 1199px) {
    .cco_menu_7 .cco_menu_item a {
        font-size:16px
    }
}

@media (max-width: 767px) {
    .cco_menu_7 {
        flex-wrap:wrap;
        margin-left: 0;
        margin-right: 0
    }

    .cco_menu_7 .cco_menu_item {
        padding-left: 0;
        padding-right: 0;
        width: 100%
    }

    .cco_menu_7 .cco_menu_item:nth-last-child(n+2) {
        margin-bottom: 15px
    }

    .cco_menu_7 .cco_menu_item a {
        width: 100%
    }
}

.cco_accordion:nth-last-child(n+2) {
    margin-bottom: 25px
}

.cco_accordion_item {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    padding: 20px
}

.cco_accordion_item:nth-last-child(n+2) {
    margin-bottom: 25px
}

.cco_accordion_item_header {
    min-height: 50px;
    padding-right: 140px;
    position: relative
}

.cco_accordion_item_header,.cco_accordion_item_toggle {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.cco_accordion_item_toggle {
    align-items: center;
    background-color: #c67b05;
    border: 2px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    cursor: pointer;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3;
    min-height: 42px;
    padding: 7px 16px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 4px;
    width: 100px
}

.cco_accordion_item_body {
    display: none
}

.cco_accordion_item_body_inner {
    border-top: 1px solid #e4e4e2;
    margin-top: 20px;
    padding-top: 20px
}

.cco_accordion_item.active .cco_accordion_item_toggle:before {
    transform: scale(-1)
}

.cco_accordion_item.active .cco_accordion_item_body {
    display: block
}

@media (max-width: 1199px) {
    .cco_accordion_item_toggle {
        font-size:16px
    }
}

@media (max-width: 767px) {
    .cco_accordion_item_header {
        min-height:24px;
        padding-right: 43px
    }

    .cco_accordion_item_toggle {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        color: #c67b05;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        min-height: 0;
        padding: 7px;
        right: -5px;
        top: -2px;
        width: auto
    }

    .cco_accordion_item_toggle:before {
        speak: never;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e911";
        font-family: Icomoon;
        font-style: normal
    }

    .cco_accordion_item_toggle_text {
        display: none
    }
}


.cco_dropdown_0 {
    margin-left: -7px;
    margin-right: -7px;
    position: relative
}

.cco_dropdown_0 .cco_dropdown_toggle {
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    margin-left: auto;
    max-width: 100%;
    min-height: 40px;
    padding: 7px 35px 7px 49px;
    position: relative
}

.cco_dropdown_0 .cco_dropdown_toggle_img {
    background-color: #fffefc;
    border-radius: 50%;
    height: 32px;
    left: 7px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: calc(50% - 16px);
    width: 46px
}

.cco_dropdown_0 .cco_dropdown_toggle_text {
    color: #000;
    display: block;
    font-family: DMSans Bold;
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%
}

.cco_dropdown_0 .cco_dropdown_toggle:after {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #dfbd26;
    content: "\e912";
    font-family: Icomoon;
    font-size: 10px;
    font-style: normal;
    line-height: 1;
    position: absolute;
    right: 7px;
    top: calc(50% - 4px)
}

.cco_dropdown_0 .cco_dropdown_content {
    background-color: #fff;
    border: 1px solid #e2e2ea;
    border-radius: 5px;
    box-sizing: 0 0 30px rgba(0,0,0,.1);
    display: none;
    overflow: hidden;
    position: absolute;
    right: 7px;
    top: calc(100% + 4px);
    width: 250px;
    z-index: 100
}

.cco_dropdown_0.active .cco_dropdown_toggle:after {
    transform: scale(-1)
}

.cco_dropdown_0.active .cco_dropdown_content {
    display: block
}

@media (max-width: 980px) {
    .cco_dropdown_0 .cco_dropdown_toggle {
        padding:4px 7px
    }

    .cco_dropdown_0 .cco_dropdown_toggle_img {
        position: static
    }

    .cco_dropdown_0 .cco_dropdown_toggle:after,.cco_dropdown_0 .cco_dropdown_toggle_text {
        display: none
    }
}



.cco_btn_0 {
    text-align: right
}

.cco_btn_0 a {
    align-items: center;
    background-color: #c67b05;
    border: 2px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    display: inline-flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    line-height: 1.3;
    min-height: 40px;
    padding: 7px 16px;
    text-align: center
}

.cco_btn_1 {
    margin-bottom: 20px
}

.cco_btn_1 a {
    align-items: center;
    background-color: #c67b05;
    border: 1px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    display: inline-flex;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    line-height: 1.3;
    max-width: 100%;
    min-height: 42px;
    padding: 8px 17px;
    text-align: center;
    width: 296px
}

.cco_btn_1 a:after {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    content: "\e92a";
    font-family: Icomoon;
    font-size: 12px;
    font-style: normal;
    line-height: 1;
    margin-left: 6px
}

.cco_btn_2 {
    border-top: 1px solid #e2e2ea;
    margin-top: 20px;
    padding-top: 20px
}

.cco_btn_2 a {
    align-items: center;
    background-color: #c67b05;
    border: 1px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    display: inline-flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    line-height: 1.3;
    min-height: 42px;
    min-width: 100px;
    padding: 8px 17px;
    text-align: center
}

.cco_btn_3 {
    text-align: right
}

.cco_btn_3 a {
    align-items: center;
    background-color: #c67b05;
    border: 2px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    display: inline-flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    line-height: 1.3;
    min-height: 30px;
    padding: 7px 16px;
    text-align: center;
    text-decoration: none;
}

@media (max-width: 980px) {
    .cco_btn_3 {
        text-align:left
    }
}

.cco_btn_4 {
    margin-left: -8px;
    margin-right: -8px
}

.cco_btn_4 button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0;
    color: #666;
    cursor: pointer;
    padding: 8px
}

.cco_btn_4 button:before {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e918";
    display: block;
    font-family: Icomoon;
    font-size: 24px;
    font-style: normal;
    line-height: 1
}

.cco_btn_4 button span {
    display: none
}

.cco_btn_5 a {
    align-items: center;
    background-color: #c67b05;
    border: 2px solid #c67b05;
    border-radius: 21px;
    color: #fff!important;
    display: inline-flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    line-height: 1.3;
    min-height: 40px;
    padding: 7px 16px;
    text-align: center
}

.cco_btn_6 {
    border-top: 1px solid #e2e2ea;
    margin-top: 20px;
    padding-top: 20px
}

.cco_btn_6 a {
    align-items: center;
    background-color: #c67b05;
    border: 1px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    // display: inline-flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    line-height: 1.3;
    min-height: 42px;
    min-width: 100px;
    padding: 8px 29px;
    text-align: center;
    text-decoration: none;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif
}

body,input,textarea,select {
    font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
}

@media (max-width: 1199px) {
    .cco_btn_6 a {
        font-size:16px
    }
}

@media (max-width: 479px) {
    .cco_btn_6 a {
        width:100%
    }
}

.ccs_loader {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    position: relative;
    width: 100%
}

.ccs_loader_fieldset {
    position: absolute;
    right: 40px;
    top: 0;
    width: auto
}

.ccs_loader_container {
    align-items: center;
    background-color: #fff;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: .8;
    position: absolute;
    right: 0;
    top: 0
}

.ccs_loader_relative_container {
    position: relative
}

.ccs_loader_item {
    -webkit-animation: ccoLoad 1.2s cubic-bezier(.5,0,.5,1) infinite;
    animation: ccoLoad 1.2s cubic-bezier(.5,0,.5,1) infinite;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top-color: #c67b05;
    box-sizing: border-box;
    display: block;
    height: 32px;
    margin: 4px;
    position: absolute;
    width: 32px
}

.ccs_loader_item:first-child {
    -webkit-animation-delay: -.45s;
    animation-delay: -.45s
}

.ccs_loader_item:nth-child(2) {
    -webkit-animation-delay: -.3s;
    animation-delay: -.3s
}

.ccs_loader_item:nth-child(3) {
    -webkit-animation-delay: -.15s;
    animation-delay: -.15s
}

@-webkit-keyframes ccoLoad {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

@keyframes ccoLoad {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

.cco_form label,.cco_form_label {
    color: #666;
    cursor: default;
    display: inline-block;
    font-family: DMSans Medium;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 12px
}

.cco_form label small,.cco_form_label small {
    color: #92929d;
    font-family: HKGrotesk Regular;
    font-size: 12px;
    font-weight: 400
}

.cco_form input:not([type=file]):not([type=checkbox]),.cco_form select,.cco_form textarea {
    background-color: #fff;
    border: 0;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    color: #666;
    display: block;
    font-size: 16px;
    line-height: 1.3;
    min-height: 60px;
    padding: 18px 20px;
    width: 100%
}

.cco_form input:not([type=file]):not([type=checkbox]):disabled,.cco_form select:disabled,.cco_form textarea:disabled {
    background-color: #fefcf4;
    cursor: not-allowed
}

.cco_form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../src/assets/icons/Caret_Down_2.svg');
    background-position: center right 20px;
    background-repeat: no-repeat;
    background-size: 12px;
    cursor: pointer;
    padding-right: 42px
}

.cco_form button:not(.cco_collapse_toggle) {
    align-items: center;
    background-color: #c67b05;
    border: 2px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    line-height: 1.3;
    max-width: 100%;
    min-height: 42px;
    padding: 7px 16px;
    text-align: center;
    width: 296px
}

.cco_form_checkbox {
    align-items: center;
    display: inline-flex!important;
    margin-bottom: 0!important;
    min-height: 60px;
    padding-left: 80px;
    position: relative
}

.cco_form_checkbox_input {
    position: absolute;
    visibility: hidden
}

.cco_form_checkbox_checkmark {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    color: #c67b1d;
    cursor: pointer;
    font-family: Icomoon;
    font-size: 20px;
    font-style: normal;
    left: 0;
    line-height: 1;
    padding: 20px;
    position: absolute;
    top: 0
}

.cco_form_checkbox_checkmark:before {
    content: "\e920";
    visibility: hidden
}

.cco_form_checkbox_input:checked~.cco_form_checkbox_checkmark:before {
    visibility: visible
}

.cco_form_checkbox_0 {
    min-height: 38px;
    padding-left: 48px
}

.cco_form_checkbox_0 .cco_form_checkbox_checkmark {
    border-radius: 10px;
    padding: 9px
}

.cco_form_heading {
    color: #666;
    font-size: 20px;
    padding-bottom: 20px
}

.cco_form_subheading {
    font-family: DMSans Medium;
    font-weight: 500;
    padding-bottom: 20px!important
}

.cco_form_note {
    font-size: 14px;
    margin-top: 12px
}

.cco_form_note a {
    color: #c67b05
}

.cco_form_note strong {
    font-family: DMSans Medium;
    font-weight: 500
}

.cco_form_fieldset {
    display: flex;
    flex-wrap: wrap;
    margin: -12.5px
}

.cco_form_fieldset:nth-last-child(n+2) {
    margin-bottom: 12.5px
}




.cco_form_fieldset_1 .cco_form_fieldset_item:nth-child(n+2) {
    padding-left: 10px
}

.cco_form_fieldset_2 {
    flex-direction: column;
    margin: 0
}

.cco_form_fieldset_2:nth-last-child(n+2) {
    margin-bottom: 24px
}

.cco_form_fieldset_2 .cco_form_fieldset_item {
    padding: 0
}

.cco_form_fieldset_2 .cco_form_fieldset_item:nth-last-child(n+2) {
    margin-bottom: 24px
}

.cco_form_message {
    line-height: 1.3
}

.cco_form_message:nth-child(n+2) {
    margin-top: 6px
}

.cco_form_message_success {
    color: #3dd598
}

.cco_form_message_error {
    color: #db3a3a
}

.cco_form_message_error_footer {
    display: block!important;
    margin-bottom: 20px;
    text-align: right
}

.cco_form_message_has_icon {
    display: flex
}

.cco_form_message_has_icon:before {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-family: Icomoon;
    font-style: normal;
    line-height: 14px;
    min-width: 28px;
    padding-right: 8px;
    padding-top: 3px
}

.cco_form_message_has_icon.cco_form_message_success:before {
    content: "\e920";
    font-size: 19px
}

.cco_form_message_has_icon.cco_form_message_error:before {
    content: "\e919";
    font-size: 14px
}

.cco_form_wrap {
    border: 1px solid #e2e2ea;
    border-radius: 23px;
    padding: 20px 25px 25px
}

.cco_form_row {
    display: flex;
    flex-wrap: wrap
}

.cco_form_row:nth-last-child(n+2) {
    margin-bottom: 40px
}

.cco_form_row_0 {
    margin-left: -12.5px;
    margin-right: -12.5px
}

.cco_form_col {
    width: 100%
}

.cco_form_col_0 {
    width: calc(50% - 12.5px)
}

.cco_form_col_1 {
    padding-right: 12.5px;
    width: 50%
}

.cco_form_col_2 {
    padding-left: 12.5px;
    width: 50%
}

.cco_form_col_3 {
    padding-left: 12.5px;
    padding-right: 12.5px;
    width: 33.33%
}

.cco_form_field_0 {
    display: flex
}

.cco_form_field_0 .cco_form_field_body {
    position: relative
}

.cco_form_field_0 .cco_form_field_aside {
    flex-grow: 1;
    padding-left: 20px;
    padding-top: 10px
}

.cco_form_field_0 .cco_form_field_label {
    background-color: #fff;
    border-radius: 23px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    cursor: pointer;
    display: block;
    height: 185px;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    width: 185px
}

.cco_form_field_0 .cco_form_field_label:before {
    border: 1px dashed #aeaeae;
    border-radius: 23px;
    content: "";
    height: calc(100% - 2px);
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 2px)
}

.cco_form_field_0 .cco_form_field_label img {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
}

.cco_form_field_0 .cco_form_field_label_icon {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #aeaeae;
    font-family: Icomoon;
    font-size: 56px;
    font-style: normal;
    left: calc(50% - 28px);
    line-height: 1;
    position: absolute;
    top: calc(50% - 44px)
}

.cco_form_field_0 .cco_form_field_label_icon_profile:before {
    content: "\e91a"
}

.cco_form_field_0 .cco_form_field_label_icon_camera:before {
    content: "\e92c"
}

.cco_form_field_0 .cco_form_field_label_icon_file:before {
    content: "\e92b"
}

.cco_form_field_0 .cco_form_field_label_caption {
    background-color: hsla(0,0%,68%,.5);
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    bottom: 0;
    color: #fff;
    font-family: HKGrotesk Regular;
    left: 0;
    letter-spacing: .4px;
    line-height: 1.5;
    padding: 4px 10px;
    position: absolute;
    text-align: center;
    width: 100%
}

.cco_form_field_0 .cco_form_field_label_caption_hover {
    transform: translateY(100%);
    transition: all .15s ease-in-out
}

.cco_form_field_0 .cco_form_field_label:hover .cco_form_field_label_caption_hover {
    transform: translateY(0)
}

.cco_form_field_0 .cco_form_field_input {
    position: absolute;
    visibility: hidden
}

.cco_form_field_1 {
    background-color: #f7f7f7!important;
    background-image: url('../../src/assets/icons/Loupe_1.svg');
    background-position: center left 15px;
    background-repeat: no-repeat;
    background-size: 16px;
    box-shadow: none!important;
    font-size: 14px!important;
    min-height: 0!important;
    padding: 12px 20px 12px 41px!important
}

.cco_form_field_1::-webkit-input-placeholder {
    color: #aeaeae
}

.cco_form_field_1::-moz-placeholder {
    color: #aeaeae
}

.cco_form_field_1:-ms-input-placeholder {
    color: #aeaeae
}

.cco_form_field_1:-moz-placeholder {
    color: #aeaeae
}

.cco_form_card {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    padding: 20px 20px 25px
}

.cco_form_card_body:nth-child(n+2) {
    margin-top: 25px
}

.cco_form_btn_0 {
    align-items: center!important;
    border-radius: 13px!important;
    border-width: 1px!important;
    font-family: DMSans Regular!important;
    font-weight: 400!important;
    justify-content: center!important;
    padding: 3px 6px!important;
    text-align: center!important;
    width: 100px!important
}

.cco_form_btn_0,.cco_form_btn_1 {
    display: flex!important;
    font-size: 14px!important;
    line-height: 1.3!important;
    min-height: 26px!important
}

.cco_form_btn_1 {
    background-color: transparent!important;
    border: 0!important;
    border-radius: 0!important;
    padding: 4px 7px!important;
    width: auto!important
}

.cco_form_btn_1:before {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #db3a3a;
    content: "\e919";
    font-family: Icomoon;
    font-style: normal
}

.cco_form_btn_2 {
    margin-top: 24px
}

.cco_form_btn_2 a {
    align-items: center;
    background-color: #c67b05;
    border: 2px solid #c67b05;
    border-radius: 21px;
    color: #fff;
    display: inline-flex;
    flex-direction: column;
    font-family: DMSans Bold;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    line-height: 1.3;
    min-height: 40px;
    padding: 7px 16px;
    text-align: center
}

.cco_form_blurb_0 {
    display: flex;
    font-size: 14px;
    justify-content: center;
    max-width: 100%;
    width: 100px
}

.cco_form_blurb_0:after {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3dd598;
    content: "\e920";
    font-family: Icomoon;
    font-size: 17px;
    font-style: normal;
    line-height: 14px;
    min-width: 17px;
    padding-left: 8px;
    padding-top: 2px
}

.cco_form_img {
    background-color: #fff;
    border-radius: 23px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    display: block;
    height: 185px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 185px
}

@media (max-width: 1199px) {
    .cco_form button:not(.cco_collapse_toggle) {
        font-size:16px
    }
}

@media (max-width: 980px) {
    .cco_form_col_0,.cco_form_col_1,.cco_form_col_2 {
        width:100%
    }

    .cco_form_col_1 {
        margin-bottom: 25px;
        padding-right: 0
    }

    .cco_form_col_2 {
        padding-left: 0
    }

    .cco_form_col_3 {
        width: 100%
    }

    .cco_form_col_3:nth-last-child(n+2) {
        margin-bottom: 25px
    }

    .cco_form_field_0 .cco_form_field_label_caption_hover {
        display: none
    }
}

@media (max-width: 767px) {
    .cco_form button:not(.cco_collapse_toggle),.cco_form_fieldset_item_medium,.cco_form_fieldset_item_small {
        width:100%
    }

    .cco_form_fieldset_0 {
        font-size: 14px
    }

    .cco_form_field_0 .cco_form_field_label {
        height: 140px;
        width: 140px
    }

    .cco_form_field_0 .cco_form_field_label,.cco_form_field_0 .cco_form_field_label:before {
        border-radius: 15px
    }

    .cco_form_field_0 .cco_form_field_label_icon {
        font-size: 48px;
        left: calc(50% - 24px);
        top: calc(50% - 40px)
    }

    .cco_form_field_0 .cco_form_field_label_caption {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        font-size: 14px
    }

    .cco_form_img {
        height: 140px;
        width: 140px
    }
}

.cco_collapse {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    padding: 20px
}

.cco_collapse:nth-last-child(n+2) {
    margin-bottom: 25px
}

.cco_collapse_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 24px;
    padding-right: 43px;
    position: relative
}

.cco_collapse_toggle {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: #c67b05;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    min-height: 0;
    padding: 7px;
    position: absolute;
    right: -5px;
    top: -2px;
    width: auto
}

.cco_collapse_toggle:before {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e911";
    display: block;
    font-family: Icomoon;
    font-style: normal
}

.cco_collapse_body,.cco_collapse_toggle_text {
    display: none
}

.cco_collapse_body_inner {
    border-top: 1px solid #e4e4e2;
    margin-top: 20px;
    padding-top: 20px
}

.cco_collapse.active .cco_collapse_toggle:before {
    transform: scale(-1)
}

.cco_collapse.active .cco_collapse_body {
    display: block
}

[data-tooltip]:before {
    border-radius: 10px;
    box-shadow: 2px 2px 1px silver;
    color: #333;
    content: attr(data-tooltip);
    opacity: 0;
    padding: 10px;
    position: absolute;
    transition: all .15s ease
}

[data-tooltip]:hover:before {
    background: #fff;
    margin-top: -20px;
    opacity: 1
}

.cco_blog {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

.cco_blog_item {
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    width: 25%
}

.cco_blog_item_wrap {
    background-color: #eaeaea;
    border: 1px solid #e2e2ea;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    color: inherit;
    display: block;
    padding-bottom: 115%;
    position: relative
}

.cco_blog_item_img {
    border-radius: 20px;
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
}

.cco_blog_item_content {
    background-color: #fff;
    border-radius: 20px;
    bottom: -1px;
    left: -1px;
    padding: 25px 25px 20px;
    position: absolute;
    width: calc(100% + 2px)
}

.cco_blog_item_title {
    font-family: DMSans Medium;
    font-size: 16px;
    font-weight: 500
}

.cco_blog_item_description {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 14px;
    line-height: 1.3;
    overflow: hidden
}

@media (max-width: 980px) {
    .cco_blog_item {
        width:50%
    }
}
.entry-title {
    font-weight: bold;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    line-height: 1.3em;
    font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
    font-size: 32px;
}
@media (max-width: 479px) {
    .cco_blog_item {
        width:100%
    }
}

.cco_sidebar {
    background-color: #fff;
    height: 100vh;
    left: 0;
    padding-top: 70px;
    position: fixed;
    top: 0;
    width: 250px;
    z-index: 100
}

.cco_sidebar_inner {
    overflow-y: auto;
    padding-bottom: 40px;
    padding-top: 45px;
}

.cco_sidebar_overlay {
    background-color: #171725;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all .15s ease-in-out;
    visibility: hidden;
    width: 100vw;
    z-index: 100
}

@media (max-width: 1199px) {
    .cco_sidebar {
        box-shadow:10px 0 30px rgba(0,0,0,.1);
        max-width: 90%;
        transform: translateX(-100%);
        transition: all .3s ease-in-out;
        width: 325px
    }

    .cco_sidebar_active {
        transform: translateX(0)
    }

    .cco_sidebar_overlay_active {
        opacity: .3;
        visibility: visible
    }
}

@media (max-width: 767px) {
    .cco_sidebar {
        background-color:#fafafb
    }
}


.cco_card_0 .cco_card_body {
    display: flex
}

.cco_card_0 .cco_card_aside {
    width: 100px
}

.cco_card_0 .cco_card_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
    width: calc(100% - 84px)
}

.cco_card_0 .cco_card_footer {
    border-top: 1px solid #e4e4e2;
    margin-top: 20px;
    padding-top: 20px
}


.cco_card_2 {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    padding: 25px 40px 30px
}

.cco_card_2 .cco_card_body {
    display: flex
}

.cco_card_2 .cco_card_aside {
    width: 100px
}

.cco_card_2 .cco_card_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
    width: calc(100% - 84px)
}

.cco_card_2 .cco_card_footer {
    border-top: 1px solid #e4e4e2;
    margin-top: 20px;
    padding-top: 20px
}
@media (max-width: 768px)  {
    .cco_header_logo {
      display: none;
    }
  }
  .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .centered-logo {
    max-height: 50px; /* Adjust as necessary for logo size */
    max-width: 100%;
    margin-left: -66px;
  }
  
@media (max-width: 767px) {
    .cco_card_2 {
        padding-left:30px;
        padding-right: 30px
    }
}



.cco_fieldset_0 {
    align-items: center;
    color: #000;
    display: flex;
    font-family: DMSans Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3
}

.cco_fieldset_0:nth-child(n+2) {
    margin-top: 12px
}

.cco_fieldset_0 .cco_fieldset_item:nth-child(n+2) {
    padding-left: 20px
}

.cco_fieldset_0 .cco_fieldset_item_large {
    flex-grow: 1
}

.cco_ad {
    align-items: center;
    display: none;
    justify-content: center
}

@media (min-width: 768px) {
    .cco_ad_0 {
        display:flex
    }
}

@media (max-width: 767px) {
    .cco_ad_1 {
        display:flex
    }
}

.cco_menu_a {
    display: flex;
    justify-content: center;
    margin-left: -13px;
    margin-right: -13px
}

.cco_menu_a .cco_menu_a_item {
    padding-left: 13px;
    padding-right: 13px
}

.cco_menu_a .cco_menu_a_item a {
    color: #000;
    display: block;
    font-size: 14px;
    line-height: 1.7;
    padding: 28px 5px 3px;
    position: relative
}

.cco_menu_a .cco_menu_a_item a:before {
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #c67b05;
    font-family: Icomoon;
    font-size: 20px;
    font-style: normal;
    left: 50%;
    line-height: 1;
    position: absolute;
    top: 0;
    transform: translateX(-50%)
}

.cco_menu_a .cco_menu_a_item.cco_menu_a_item_icon_crane a:before {
    content: "\e903"
}

.cco_menu_a .cco_menu_a_item.cco_menu_a_item_icon_certificate a:before {
    content: "\e90a"
}

.cco_menu_a .cco_menu_a_item.cco_menu_a_item_icon_arrow_up a:before {
    content: "\e915"
}

.cco_menu_a .cco_menu_a_item.cco_menu_a_item_icon_click a:before {
    content: "\e902"
}

@media (max-width: 479px) {
    .cco_menu_a .cco_menu_a_item {
        padding-left:5px;
        padding-right: 5px
    }

    .cco_menu_a {
        justify-content: space-around;
        margin-left: -5px;
        margin-right: -5px
    }
}

.cco_menu_b {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px
}

.cco_menu_b .cco_menu_b_item {
    padding: 5px
}

.cco_menu_b .cco_menu_b_item a {
    background-color: rgba(223,189,38,.1);
    border-radius: 5px;
    font-family: HKGrotesk Medium;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px
}

.cco_section_a {
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -10px 30px 0 rgba(0,0,0,.1);
    display: none;
    left: 0;
    padding: 15px 25px 7px;
    position: fixed;
    width: 100%
}

@media (min-width: 768px) {
    .cco_section_a {
        display:none
    }
}

.cco_section_b {
    margin-left: auto;
    margin-right: auto;
    max-width: 1215px;
    padding: 70px 40px 90px
}

@media (max-width: 767px) {
    .cco_section_b {
        padding-left:25px;
        padding-right: 25px
    }
}

.cco_text_a h1 {
    font-size: 32px;
    text-align: center
}

.cco_text_b h2 {
    color: #12022f;
    font-size: 24px
}

.cco_text_b_icon_crane {
    font-feature-settings: normal;
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    align-items: center;
    display: flex;
    font-family: Icomoon!important;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none
}

.cco_text_b_icon_crane:before {
    background-color: #c67b05;
    border-radius: 5px;
    color: #fff;
    content: "\e903";
    font-size: 24px;
    margin-right: 10px;
    padding: 4px
}

.cco_text_c h5 {
    color: #000;
    padding-bottom: 0
}

.cco_text_d p {
    font-size: 14px
}

.cco_text_e {
    padding-bottom: 5px;
    padding-top: 5px
}

.cco_form_a {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    position: relative;
    width: 100%
}

.cco_form_a input[type=text] {
    background-color: #fff;
    border: 0;
    border-radius: 15px;
    color: #666;
    font-size: 14px;
    line-height: 1.7;
    padding: 13px 70px 13px 140px;
    position: relative;
    width: 100%
}

.cco_form_a input[type=text] ::-moz-placeholder {
    color: #666;
    font-size: 14px;
    line-height: 1.7
}

.cco_form_a input[type=text] ::placeholder {
    color: #666;
    font-size: 14px;
    line-height: 1.7
}

.cco_form_a select {
    background-color: #f7f7f7;
    border: 0;
    border-radius: 7px;
    color: #666;
    font-size: 12px;
    left: 10px;
    line-height: 1.3;
    padding: 6px 12px 6px 8px;
    width: 115px;
    z-index: 100
}

.cco_form_a select,.cco_form_a_icon_search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.cco_form_a_icon_search {
    background-color: #c67b05;
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    height: 40px;
    padding: 5px;
    right: 5px;
    width: 44px
}

.cco_form_a_icon_search span {
    display: none
}

.cco_form_a_icon_search:before {
    font-feature-settings: normal;
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    content: "\e914";
    font-family: Icomoon!important;
    font-size: 24px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    left: 50%;
    line-height: 1;
    position: absolute;
    text-transform: none;
    top: 50%;
    transform: translate(-50%,-50%)
}

@media (max-width: 767px) {
    .cco_form_a input[type=text] {
        padding-left:25px
    }

    .cco_form_a select {
        background-color: #fff;
        margin-top: 25px;
        padding-left: 25px;
        padding-right: 25px;
        position: static;
        width: 100%
    }

    .cco_form_a_icon_search {
        top: 5px;
        transform: translateY(0)
    }
}

.cco_row_a {
    margin-bottom: 25px
}

.cco_row_b {
    justify-content: center;
    margin-bottom: 40px
}

.cco_row_b,.cco_row_c {
    display: flex;
    flex-wrap: wrap
}

.cco_row_d {
    margin-bottom: 15px
}

.cco_col_a:first-child {
    margin-right: 10px
}

.cco_col_b {
    width: 50%
}

.cco_col_b:first-child {
    padding-right: 32px
}

@media (max-width: 767px) {
    .cco_col_b {
        width:100%
    }

    .cco_col_b:first-child {
        padding-right: 0
    }

    .cco_col_b:nth-last-child(n+2) {
        margin-bottom: 25px
    }
}

.cco_card_a {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,.05);
    padding: 25px
}

.cco_card_a_header {
    padding-bottom: 10px
}

.cco_card_a:nth-last-child(n+2) {
    margin-bottom: 25px
}

.cco_header {
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    left: 50%;
    padding-bottom: 25px;
    padding-top: 30px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: 100
}

.cco_header_container {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    position: relative;
    width: 80%
}

.cco_header_logo img {
    display: block;
    max-height: 65px;
    max-width: 65px;
    -o-object-fit: contain;
    object-fit: contain
}

@media (max-width: 980px) {
    .cco_header_logo img {
        max-height:60px;
        max-width: 60px
    }
}

@media (max-width: 767px) {
    .cco_header_logo img {
        max-height:55px;
        max-width: 55px
    }
}

.cco_header_nav {
    flex-grow: 1;
    padding-left: 40px
}

.cco_header_nav_desktop {
    display: flex
}

@media (max-width: 980px) {
    .cco_header_nav_desktop {
        display:none
    }
}

@media (min-width: 981px) {
    .cco_header_nav_mobile {
        display:none
    }
}

.cco_header_nav_mobile_open .cco_header_list_3 {
    display: block
}

.cco_header_list_1 {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    list-style: none;
}

.cco_header_list_1_item {
    padding: 7.25px 10px
}

.cco_header_list_1_item a {
    color: #666;
    display: block;
    font-family: DMSans Medium;
    font-size: 18px;
    line-height: 1.3;
    padding: 5px;
    text-decoration: none;
}

.cco_header_list_2 {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 20px;
    list-style:none;
    
}

.cco_header_list_2_item {
    padding-left: 15px;
    padding-right: 15px
}

.cco_header_list_2_item a {
    border-radius: 24px;
    display: block;
    font-family: DMSans Bold;
    font-size: 18px;
    min-width: 170px;
    padding: 8.5px 30px;
    text-align: center
}

.cco_header_list_2_item_register a {
    background-color: #c67b05;
    border: 2px solid #c67b05;
    color: #fff !important;
    text-decoration: none;
}

.cco_header_list_2_item_sign a {
    background-color: #fff;
    border: 2px solid #c67b05;
    color: #c67b05
}

.cco_header_list_3 {
    background-color: #fff;
    border-top: 3px solid #c67b05;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    display: none;
    left: 0;
    padding: 4% 5% 5%;
    position: absolute;
    top: calc(100% + 25px);
    width: 100%
}

.cco_header_list_3_item:nth-last-child(n+2) {
    border-bottom: 1px solid rgba(198,123,5,.1)
}

.cco_header_list_3_item a {
    color: #c67b05;
    display: inline-block;
    padding: 8px 30px
}

.cco_header_list_3_item_register,.cco_header_list_3_item_sign {
    padding-bottom: 5px;
    padding-top: 5px
}

.cco_header_list_3_item_register a,.cco_header_list_3_item_sign a {
    border: 1px solid #c67b05;
    border-radius: 22px;
    font-family: DMSans Bold;
    padding-bottom: 5px;
    padding-top: 5px
}

@media (max-width: 767px) {
    .cco_header_list_3 {
        padding:5% 5% 7%
    }
}

@media (max-width: 479px) {
    .cco_header_list_3 {
        padding:5% 5% 10%
    }
}

.cco_header_list_4 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 15px
}

.cco_header_list_4_item {
    padding: 5px 10px
}

.cco_header_list_4_item a {
    border-radius: 24px;
    display: block;
    font-family: DMSans Bold;
    font-size: 18px;
    min-width: 170px;
    padding: 6px 30px;
    text-align: center
}

.cco_header_list_4_item_register a {
    background-color: #c67b05;
    border: 2px solid #c67b05;
    color: #fff
}

.cco_header_list_4_item_sign a {
    background-color: #fff;
    border: 2px solid #c67b05;
    color: #c67b05
}

@media (max-width: 479px) {
    .cco_header_list_4_item,.cco_header_list_4_item a {
        width:100%
    }
}

.cco_header_toggle {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    margin-left: auto;
    padding: 0;
    transform: rotate(180deg)
}

.cco_header_toggle_icon {
    display: block
}

.cco_header_toggle_icon:before {
    font-feature-settings: normal;
    speak: never;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #c67b05;
    content: "\e918";
    display: block;
    font-family: Icomoon!important;
    font-size: 22px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none
}

@media (max-width: 767px) {
    .cco_header_toggle_icon:before {
        font-size:20px
    }
}

.loginPage .cco_header {
    box-shadow: none !important;
    max-width: 1360px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 50px
}

.loginPage .cco_header_container {
    max-width: 100%;
    width: 100%
}

.loginPage .cco_header_logo img {
    content: url('../../src/assets/images/Logo_CCO_1.png');
    max-height: 80px;
    max-width: 80px
}

.loginPage .cco_header_list_1_item a {
    color: #fff
}

.loginPage .cco_header_list_2_item_sign a {
    border-color: #fff
}

.loginPage .cco_header_list_3 {
    box-shadow: none
}

.loginPage .cco_header_toggle_icon:before {
    color: #fff
}

@media (max-width: 980px) {
    .loginPage .cco_header {
        padding-top:40px
    }

    .loginPage .cco_header_logo img {
        max-height: 70px;
        max-width: 70px
    }

    .loginPage .cco_header_list_3 {
        top: calc(100% + 15px)
    }
}

@media (max-width: 767px) {
    .loginPage .cco_header {
        padding-top:30px
    }

    .loginPage .cco_header_logo img {
        max-height: 65px;
        max-width: 65px
    }
}

@media (max-width: 767px) and (max-width:767px) {
    .loginPage .cco_header_toggle_icon:before {
        font-size:22px
    }
}

@media (min-width: 1200px) {
    .cco_img_a {
        padding-top:100px
    }
}

.page-template-faq {
    background-color: #fafafb
}

.cco_section_a {
	display: none;
}

.cco_blurb_2 {
	align-items: flex-start;
}
.cco_blurb_2 .cco_blurb_title {
	display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
	overflow: hidden;
}

@media (min-width: 1200px) {
	body #page-container .cco_et_img_0 {
		height: calc(100% - 205px);
		top: 205px;
	}
}

@media (max-width: 1199px) {
	.cco_et_col_0,
	.cco_et_col_1{
		width: 100% !important;
	}
}

.cco_et_text_0 {
	font-family: 'DMSans Bold';
	font-size: 16px;
	line-height: 1.5;
}

.cco_et_text_0 a {
	color: #fff;
	font-size: 18px;
}
.cco_et_text_0 a .text {
	text-decoration: underline;
}
.cco_et_text_0 a[target=_blank]::after {
	speak: never;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #fff;
	content: "\e92a";
	font-family: Icomoon;
	font-size: 14px;
	font-style: normal;
	line-height: 1;
	margin-left: 4px;
}

.cco_et_text_0 ul {
	font-family: 'DMSans Bold';
	font-size: 20px;
	line-height: 1.5;
	padding-bottom: 30px;
}

ol ul{
    list-style: none;
}

embed, iframe, object, video {
    max-width: 100%;
}

.tpCards a:hover{
    color: #666;
}
.cco_menu_items {
    display: flex;
   
}

.menu-icon {
    margin-left: 20px;
    margin-top :2px;
    width: 24px;
    height: 24px;
    fill: #666666; /* Set the initial color for SVG icons */
    transition: fill 0.3s ease; /* Smooth transition for hover effect */
}
.center-text{ text-align: center; } 

.display-table{ display: table; height: 100%; width: 100%; }

.display-table-cell{ display: table-cell; vertical-align: middle; }



::-webkit-input-placeholder { font-size: .9em; letter-spacing: 1px; }

::-moz-placeholder { font-size: .9em; letter-spacing: 1px; }

:-ms-input-placeholder { font-size: .9em; letter-spacing: 1px; }

:-moz-placeholder { font-size: .9em; letter-spacing: 1px; }


.full-height{ height: 100%; }

.position-static{ position: static; }

.font-white{ color: #fff; }


/* ---------------------------------
3. MAIN SECTION
--------------------------------- */

.main-area{ position: relative; height: 100vh; z-index: 1; padding: 0 20px; background-size: cover; color: #fff; }

.main-area:after{ content:''; position: absolute; top: 0; bottom: 0;left: 0; right: 0; z-index: -1;  
	opacity: .4; background: #000; }

.main-area .desc{ margin: 20px auto; max-width: 1100px;  font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
    font-size: 16px;
    color: white;}
.title{
    font-weight:100;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    line-height: 1.3em;
    font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
    font-size: 50px;
    color: white;
    margin-top: 160px;
}


.main-area .social-btn{ position: absolute; bottom: 40px; width: 100%; left: 50%; transform: translateX(-50%); }


.main-area{
    background-Image: url(../assets/images/countdown-3-1600x900.png);
    background-color: rgba(0, 0, 0, 0.5); 
}
.img{
    margin-top: 50px;
    width: 150px;
    height: 150px;
}
.social-btn {
    display: flex; /* This makes the list items align in a row */
    list-style: none; /* Remove default bullet points */
    padding: 0; /* Remove default padding */
    margin: 0; 
    justify-content: center;/* Remove default margin */
    
    li {
      margin-right: 15px; /* Space between links */
      
      a {
        text-decoration: none;
        color: white; /* Default link color */
        font-size: 16px;
  
        &:hover {
          color: pink; /* Change color to pink on hover */
        }
      }
    }
  }
  









// @import 'am-components/main.scss';
// @import 'components/main.scss';
// @import 'home.scss';
// @import 'base/main.scss';

// @font-face {
//     font-display: block;
//     font-family: Icomoon;
//     font-style: normal;
//     font-weight: 400;
//     src: url(../../src/assets/fonts/Icomoon/Icomoon.woff?knrjde) format("woff"),url(../../src/assets/fonts/Icomoon/Icomoon.woff2?knrjde) format("woff2")
// }

// @font-face {
//     font-display: swap;
//     font-family: DMSans Bold;
//     font-style: normal;
//     font-weight: 400;
//     src: url(../../src/assets/fonts/DMSans/DMSans-Bold.woff) format("woff"),url(../../src/assets/fonts/DMSans/DMSans-Bold.woff2) format("woff2")
// }

// @font-face {
//     font-display: swap;
//     font-family: DMSans Medium;
//     font-style: normal;
//     font-weight: 400;

//     src: url(../../src/assets/fonts/DMSans/DMSans-Medium.woff) format("woff"),url(../../src/assets/fonts/DMSans/DMSans-Medium.woff2) format("woff2")
// }

// @font-face {
//     font-display: swap;
//     font-family: DMSans Regular;
//     font-style: normal;
//     font-weight: 400;

//     src: url(../../src/assets/fonts/DMSans/DMSans-Regular.woff) format("woff"),url(../../src/assets/fonts/DMSans/DMSans-Regular.woff2) format("woff2")
// }

// @font-face {
//     font-display: swap;
//     font-family: HKGrotesk Regular;
//     font-style: normal;
//     font-weight: 400;

//     src: url(../../src/assets/fonts/HKGrotesk/HKGrotesk-Regular.woff) format("woff"),url(../../src/assets/fonts/HKGrotesk/HKGrotesk-Regular.woff2) format("woff2")
// }

// @font-face {
//     font-display: swap;
//     font-family: HKGrotesk Medium;
//     font-style: normal;
//     font-weight: 500;

//     src: url(../../src/assets/fonts/HKGrotesk/HKGrotesk-Medium.woff2) format("woff2"),url(../../src/assets/fonts/HKGrotesk/HKGrotesk-Medium.woff) format("woff")
// }
// @font-face {
//     font-display: swap;
//     font-family: Poppins Medium;
//     font-style:normal;
//     font-weight: 500;

//     src: url(../../src/assets/fonts/ionicons.woff) format("woff2"),
// }

// body.et_cover_background {
//     background-size: cover!important;
//     background-position: top!important;
//     background-repeat: no-repeat!important;
//     background-attachment: fixed;
// }

// body, input, textarea, select {
//     font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
// }

// body {
//     line-height: 1.5em;
// }

// body {
//     font-family: Open Sans,Arial,sans-serif;
//     font-size: 14px;
//     color: #666;
//     background-color: #fff;
//     line-height: 1.7em;
//     font-weight: 500;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

// a, abbr, acronym, address, applet, b, big, blockquote, body, center, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, tt, u, ul, var {
//     margin: 0;
//     padding: 0;
//     border: 0;
//     outline: 0;
//     font-size: 100%;
//     -ms-text-size-adjust: 100%;
//     -webkit-text-size-adjust: 100%;
//     vertical-align: baseline;
//     background: transparent;
//     list-style: none;
// }
// h1, h2, h3, h4, h5, h6 {
//     color: #171725;
// }
// * {
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
// }

// body {
//     font-weight: 400!important
// }

// body.overflow-hidden {
//     overflow: hidden
// }

// a, a:hover {
//     text-decoration: none;
// }

// a, a:hover {
//     color: #c67b05;
// }

// img {
//     max-width: 100%;
//     height: auto;
// }

// #page-container {
//     margin-top: 0!important;
//     overflow: hidden
// }

// #main-content .container:before,#sidebar {
//     display: none
// }

// #left-area {
//     padding: 0 0 58px!important
// }

// @media (min-width: 981px) {
//     #left-area {
//         float:none!important;
//         width: 100%!important
//     }
// }

// .loading-indicator {
//     text-align: center
// }

// .block-ui {
//     min-height: 3em;
//     position: relative
// }

// .block-ui-container {
//     bottom: 0;
//     height: 100%;
//     left: 0;
//     min-height: 2em;
//     overflow: hidden!important;
//     position: absolute;
//     right: 0;
//     top: 0;
//     width: 100%;
//     z-index: 1010
// }

// .block-ui-container:focus {
//     outline: none
// }

// .block-ui-overlay {
//     background-color: #b8b8b8;
//     filter: alpha(opacity=50);
//     height: 100%;
//     opacity: .75;
//     width: 100%
// }

// .block-ui-message-container {
//     left: 0;
//     position: absolute;
//     right: 0;
//     text-align: center;
//     top: 50%;
//     transform: translateY(-50%);
//     z-index: 10001
// }

// .block-ui-message {
//     background: none;
//     color: #333;
//     margin-top: 9%;
//     z-index: 1011
// }

// #indicator {
//     -webkit-animation: spin 1s linear infinite;
//     animation: spin 1s linear infinite;
//     height: 100px;
//     left: calc(50% - 50px);
//     position: absolute;
//     top: calc(50% - 50px);
//     width: 100px
// }

// #circle {
//     fill: none;
//     stroke: #c67b05;
//     stroke-linecap: round;
//     stroke-width: 4;
//     -webkit-animation: draw 3s ease-in-out infinite;
//     animation: draw 3s ease-in-out infinite
// }

// @-webkit-keyframes draw {
//     0% {
//         stroke-dasharray: 20,282.6
//     }

//     50% {
//         stroke-dasharray: 200,282.6
//     }

//     to {
//         stroke-dasharray: 20,282.6
//     }
// }

// @keyframes draw {
//     0% {
//         stroke-dasharray: 20,282.6
//     }

//     50% {
//         stroke-dasharray: 200,282.6
//     }

//     to {
//         stroke-dasharray: 20,282.6
//     }
// }

// @-webkit-keyframes spin {
//     to {
//         transform: rotate(1turn)
//     }
// }

// @keyframes spin {
//     to {
//         transform: rotate(1turn)
//     }
// }

// .hostedformButtonStyle {
//     border: "none";
//     font-family: DMSans Bold;
//     line-height: 1;
//     text-align: center;
//     width: "200px"
// }

// .hostedformButtonStyle,.hostedformContainerStyle {
//     align-items: "center";
//     background-color: #c67b05;
//     color: #fff;
//     display: "inline-flex";
//     flex-direction: "column";
//     font-size: "18px";
//     font-weight: "700";
//     justify-content: "center";
//     max-width: "100%";
//     padding: "7px 16px"
// }

// .hostedformContainerStyle {
//     border: "2px solid #c67b05";
//     border-radius: "21px";
//     font-family: DM Sans Bold;
//     line-height: 1.3;
//     min-height: "42px";
//     text-align: "center";
//     width: "296px"
// }

// .ccolinks {
//     align-items: center!important;
//     background-color: transparent!important;
//     border: none!important;
//     border-radius: 0!important;
//     color: #c67b05!important;
//     display: inline-flex!important;
//     font-size: 12px!important;
//     height: 0!important;
//     line-height: 0px!important;
//     margin: 0!important
// }

// .custom-post-breadcrumb .lms-breadcrumbs,.custom-post-breadcrumb .lms-breadcrumbs .breadcrumb-trail {
//     display: inline
// }

// .custom-post-breadcrumb .lms-breadcrumbs .breadcrumb-trail>span:nth-child(-n+2),.custom-post-breadcrumb .lms-breadcrumbs>span:nth-child(-n+2) {
//     display: none
// }

// .radio-button-item {
//     align-items: center;
//     box-shadow: none!important;
//     display: grid;
//     justify-content: space-between;
//     margin-bottom: 5px
// }

// .description {
//     margin-right: 10px
// }

// .radio-buttons input:not([type=file]):not([type=checkbox]) {
//     box-shadow: none!important;
//     display: flex;
//     transform: scale(1.1)
// }

// .radio-buttons-yes {
//     margin-right: 10px
// }

// .radio-buttons-no,.radio-buttons-yes {
//     text-align: center!important
// }

// .radio-buttons-no {
//     margin-left: 10px
// }

// [class*=" cco_icon_"],[class^=cco_icon_] {
//     font-feature-settings: normal;
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #666;
//     font-family: Icomoon!important;
//     font-style: normal;
//     font-variant: normal;
//     font-weight: 400;
//     line-height: 1;
//     text-transform: none
// }

// .cco_icon_arrow_up_1:before {
//     content: "\e915"
// }

// .cco_icon_arrow_down_1:before {
//     content: "\e916"
// }

// .cco_icon_arrow_right_1:before {
//     content: "\e906"
// }

// .cco_icon_avatar_1:before {
//     content: "\e910"
// }

// .cco_icon_avatar_2:before {
//     content: "\e91a"
// }

// .cco_icon_badge_1_path_0:before {
//     content: "\e92d"
// }

// .cco_icon_badge_1_path_1:before {
//     content: "\e92e"
// }

// .cco_icon_camera_1_path_0:before {
//     content: "\e91b"
// }

// .cco_icon_camera_1_path_1:before {
//     content: "\e91c";
//     margin-left: -1em
// }

// .cco_icon_camera_1_path_2:before {
//     content: "\e91d";
//     margin-left: -1em
// }

// .cco_icon_camera_2:before {
//     content: "\e92c"
// }

// .cco_icon_caret_down_1:before {
//     content: "\e912"
// }

// .cco_icon_caret_down_2:before {
//     content: "\e911"
// }

// .cco_icon_caret_left_1:before {
//     content: "\e927"
// }

// .cco_icon_caret_left_2:before {
//     content: "\e929"
// }

// .cco_icon_caret_right_1:before {
//     content: "\e900"
// }

// .cco_icon_caret_right_2:before {
//     content: "\e928"
// }

// .cco_icon_cart_1:before {
//     content: "\e901"
// }

// .cco_icon_certificate_1:before {
//     content: "\e90a"
// }

// .cco_icon_chatting_1:before {
//     content: "\e907"
// }

// .cco_icon_check_1:before {
//     content: "\e90d"
// }

// .cco_icon_check_2:before {
//     content: "\e90c"
// }

// .cco_icon_check_3_path_0:before {
//     content: "\e91e"
// }

// .cco_icon_check_3_path_1:before {
//     content: "\e91f";
//     margin-left: -1em
// }

// .cco_icon_check_4:before {
//     content: "\e920"
// }

// .cco_icon_click_1:before {
//     content: "\e902"
// }

// .cco_icon_close_1:before {
//     content: "\e919"
// }

// .cco_icon_crane_1:before {
//     content: "\e903"
// }

// .cco_icon_dashboard_1:before {
//     content: "\e90b"
// }

// .cco_icon_driver_license_1:before {
//     content: "\e921"
// }

// .cco_icon_ellipsis_1:before {
//     content: "\e905"
// }

// .cco_icon_gear_1:before {
//     content: "\e90f"
// }

// .cco_icon_loader_1_path_0:before {
//     content: "\e922"
// }

// .cco_icon_loader_1_path_1:before {
//     content: "\e923";
//     margin-left: -1em
// }

// .cco_icon_loader_1_path_2:before {
//     content: "\e924";
//     margin-left: -1em
// }

// .cco_icon_loader_1_path_3:before {
//     content: "\e925";
//     margin-left: -1em
// }

// .cco_icon_loader_1_path_4:before {
//     content: "\e926";
//     margin-left: -1em
// }

// .cco_icon_logout_1:before {
//     content: "\e90e"
// }

// .cco_icon_loupe_1:before {
//     content: "\e914"
// }

// .cco_icon_loupe_2:before {
//     content: "\e913"
// }

// .cco_icon_megaphone_1:before {
//     content: "\e917"
// }

// .cco_icon_menu_1:before {
//     content: "\e918"
// }

// .cco_icon_paper_1:before {
//     content: "\e909"
// }

// .cco_icon_paper_plane_1:before {
//     content: "\e908"
// }

// .cco_icon_plus_1:before {
//     content: "\e904"
// }

// .cco_icon_external_1:before {
//     content: "\e92a"
// }

// .cco_icon_file_1:before {
//     content: "\e92b"
// }

// .et_builder_inner_content .cco_et_section_0 {
//     overflow: hidden;
//     padding-bottom: 0;
//     padding-top: 0
// }

// .et_builder_inner_content .cco_et_section_0>.et_pb_row {
//     display: flex;
//     flex-wrap: wrap;
//     margin-top: 0;
//     max-width: 1360px;
//     min-height: 100vh;
//     padding-left: 40px;
//     padding-right: 40px;
//     padding-top: 150px;
//     position: static;
//     width: 100%
// }

// @media (min-width: 981px) and (max-width:1199px) {
//     .et_builder_inner_content .cco_et_section_0>.et_pb_row {
//         padding-top:180px
//     }
// }

// @media (max-width: 767px) {
//     .et_builder_inner_content .cco_et_section_0>.et_pb_row {
//         padding-top:130px
//     }
// }

// .et_builder_inner_content .cco_et_row_0 {
//     padding-bottom: 40px;
//     padding-top: 0
// }

// .et_builder_inner_content .cco_et_row_1 {
//     display: flex;
//     flex-wrap: wrap;
//     margin-left: -12.5px;
//     margin-right: -12.5px;
//     padding-bottom: 0;
//     padding-top: 0
// }

// .et_builder_inner_content .cco_et_col_0 {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     margin-right: 0;
//     padding-bottom: 70px;
//     padding-right: 2.75%;
//     padding-top: 50px;
//     width: 50%;
//     z-index: 100
// }

// @media (max-width: 1199px) {
//     .et_builder_inner_content .cco_et_col_0 {
//         margin-bottom:20px;
//         padding-bottom: 0;
//         padding-right: 0;
//         padding-top: 0;
//         width: 100%
//     }
// }

// @media (max-width: 980px) {
//     .et_builder_inner_content .cco_et_col_0 {
//         display:block
//     }
// }

// .et_builder_inner_content .cco_et_col_1 {
//     padding-bottom: 0;
//     padding-top: 0;
//     position: static;
//     width: 50%
// }

// @media (max-width: 1199px) {
//     .et_builder_inner_content .cco_et_col_1 {
//         margin-top:auto;
//         width: 100%
//     }
// }

// .et_builder_inner_content .cco_et_col_2,.et_builder_inner_content .cco_et_col_3 {
//     margin-bottom: 30px!important;
//     margin-right: 0!important;
//     padding-left: 12.5px;
//     padding-right: 12.5px;
//     width: auto!important
// }

// .et_builder_inner_content .cco_et_text_0,.et_builder_inner_content .cco_et_text_0 h1,.et_builder_inner_content .cco_et_text_0 h2,.et_builder_inner_content .cco_et_text_0 h3,.et_builder_inner_content .cco_et_text_0 h4,.et_builder_inner_content .cco_et_text_0 h5,.et_builder_inner_content .cco_et_text_0 h6 {
//     color: #fff!important
// }

// .et_builder_inner_content .cco_et_text_0 h1 {
//     font-size: 72px
// }

// .et_builder_inner_content .cco_et_text_0 h2 {
//     font-size: 20px;
//     line-height: 1.5!important
// }

// .et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h1,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h2,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h3,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h4,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h5,.et_builder_inner_content .cco_et_text_0 .et_pb_text_inner>h6 {
//     padding-bottom: 30px
// }

// @media (max-width: 980px) {
//     .et_builder_inner_content .cco_et_text_0 h1 {
//         font-size:56px
//     }
// }

// @media (max-width: 767px) {
//     .et_builder_inner_content .cco_et_text_0 h1 {
//         font-size:40px
//     }
// }

// .et_builder_inner_content .cco_et_img_0 .et_pb_image_wrap,.et_builder_inner_content .cco_et_img_0 img {
//     width: 100%
// }

// @media (min-width: 1200px) {
//     .et_builder_inner_content .cco_et_img_0 {
//         height:100%;
//         left: 50%;
//         position: absolute;
//         top: 50px;
//         width: 50%
//     }

//     .et_builder_inner_content .cco_et_img_0 .et_pb_image_wrap {
//         height: 100%
//     }

//     .et_builder_inner_content .cco_et_img_0 .et_pb_image_wrap img {
//         height: 100%;
//         max-width: 1280px;
//         -o-object-fit: contain;
//         object-fit: contain
//     }
// }

// @media (max-width: 1199px) {
//     .et_builder_inner_content .cco_et_img_0 {
//         margin-left:-40px;
//         margin-right: -40px
//     }
// }


// .cco_section_2 {
//     background-color: #fff;
//     border-bottom: 1px solid #e2e2ea;
//     padding: 5px 40px;
//     position: relative;
//     z-index: 100
// }

// @media (max-width: 767px) {
//     .cco_section_2 {
//         display:none
//     }
// }

// .cco_section_3 {
//     margin-left: 250px;
//     margin-right: auto;
//     max-width: 1350px;
//     padding: 40px 40px 17.5px
// }

// @media (min-width: 1851px) {
//     .cco_section_3 {
//         margin-left:auto
//     }
// }

// @media (max-width: 1199px) {
//     .cco_section_3 {
//         margin-left:auto
//     }
// }

// @media (max-width: 767px) {
//     .cco_section_3 {
//         padding-bottom:15px;
//         padding-left: 25px;
//         padding-right: 25px
//     }
// }

// .cco_section_4 {
//     margin-left: 250px;
//     margin-right: auto;
//     max-width: 1350px;
//     padding: 17.5px 40px 40px
// }

// @media (min-width: 1851px) {
//     .cco_section_4 {
//         margin-left:auto
//     }
// }

// @media (max-width: 1199px) {
//     .cco_section_4 {
//         margin-left:auto
//     }
// }

// @media (max-width: 767px) {
//     .cco_section_4 {
//         padding-left:25px;
//         padding-right: 25px;
//         padding-top: 15px
//     }
// }




// .cco_row_0 {
//     background-color: #fff;
//     border-radius: 10px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     display: flex;
//     margin-top: 80px;
//     padding: 25px 40px 30px
// }

// @media (max-width: 980px) {
//     .cco_row_0 {
//         margin-top:0
//     }
// }

// @media (max-width: 767px) {
//     .cco_row_0 {
//         background-color:transparent;
//         border-radius: 0;
//         box-shadow: none;
//         display: block;
//         padding: 0
//     }
// }

// @media (min-width: 768px) {
//     .cco_row_1 {
//         margin-bottom:35px
//     }
// }

// .cco_row_2 {
//     margin-bottom: 20px
// }

// .cco_row_3 {
//     border: 1px solid #e2e2ea;
//     border-radius: 23px;
//     padding: 20px 25px 25px
// }

// @media (min-width: 768px) {
//     .cco_row_3 {
//         margin-bottom:35px
//     }
// }

// @media (max-width: 767px) {
//     .cco_row_4 {
//         display:none
//     }
// }

// .cco_row_5 {
//     display: flex;
//     margin-bottom: 0
// }

// .cco_row_6 {
//     border: 1px solid #e2e2ea;
//     border-radius: 23px;
//     display: flex;
//     flex-wrap: wrap;
//     padding: 20px 25px 25px
// }

// @media (min-width: 768px) {
//     .cco_row_6 {
//         margin-bottom:35px
//     }
// }

// .cco_row_7 {
//     display: flex;
//     // flex-wrap: wrap;
//     margin-bottom: 20px
// }

// .cco_row_8 {
//     margin-bottom: 40px
// }

// .cco_row_9 {
//     margin-bottom: 40px
// }

// .cco_row_10,.cco_row_9 {
//     border: 1px solid #e2e2ea;
//     border-radius: 23px;
//     padding: 20px 25px 25px
// }

// .cco_row_10 {
//     margin-bottom: 20px
// }

// .cco_row_11 {
//     background-color: #fff;
//     border-radius: 10px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     padding: 25px 40px 30px
// }

// @media (max-width: 767px) {
//     .cco_row_11 {
//         padding-left:30px;
//         padding-right: 30px
//     }
// }

// @media (min-width: 768px) {
//     .cco_row_11 {
//         margin-bottom:35px
//     }
// }


// .cco_col_0 {
//     padding-right: 20px;
//     width: 30%
// }

// .cco_col_0,.cco_col_1 {
//     display: flex;
//     flex-direction: column;
//     justify-content: center
// }

// .cco_col_1 {
//     padding-left: 20px;
//     width: 70%
// }

// .cco_col_2 {
//     padding-right: 15px
// }

// .cco_col_2,.cco_col_3 {
//     display: flex;
//     flex-direction: column;
//     justify-content: center
// }

// .cco_col_3 {
//     flex-grow: 1
// }

// .cco_col_4 {
//     padding-right: 15px
// }

// @media (max-width: 767px) {
//     .cco_col_4 {
//         margin-bottom:20px;
//         padding-right: 0
//     }
// }

// .cco_col_5 {
//     padding-left: 15px
// }

// @media (max-width: 767px) {
//     .cco_col_5 {
//         padding-left:0
//     }
// }

// .cco_col_6 {
//     width: 100%
// }

// .cco_col_6:nth-last-child(n+2) {
//     padding-right: 12.5px;
//     width: 50%
// }

// @media (max-width: 980px) {
//     .cco_col_6:nth-last-child(n+2) {
//         margin-bottom:25px;
//         padding-right: 0;
//         width: 100%
//     }
// }

// .cco_col_7 {
//     padding-left: 12.5px;
//     width: 50%
// }

// @media (max-width: 980px) {
//     .cco_col_7 {
//         padding-left:0;
//         width: 100%
//     }
// }

// .cco_col_8 {
//     width: calc(33.33% - 16.66px)
// }

// .cco_col_8:nth-last-child(n+2) {
//     margin-right: 25px
// }

// @media (max-width: 980px) {
//     .cco_col_8 {
//         width:calc(50% - 12.5px)
//     }

//     .cco_col_8:nth-last-child(n+2) {
//         margin-bottom: 25px
//     }

//     .cco_col_8:nth-child(2n) {
//         margin-right: 0
//     }
// }

// @media (max-width: 767px) {
//     .cco_col_8 {
//         width:100%
//     }

//     .cco_col_8:nth-last-child(n+2) {
//         margin-bottom: 25px;
//         margin-right: 0
//     }
// }

// .cco_col_9 {
//     display: none;
//     flex-direction: column;
//     justify-content: center;
//     max-width: 30%;
//     padding-right: 20px
// }

// @media (max-width: 1199px) {
//     .cco_col_9 {
//         display:flex
//     }
// }

// .cco_col_10 {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     padding-right: 20px;
//     width: 30%
// }

// @media (max-width: 1199px) {
//     .cco_col_10 {
//         padding-left:20px
//     }
// }

// .cco_col_11 {
//     display: flex;
//     flex-direction: column;
//     flex-grow: 1;
//     justify-content: center;
//     max-width: 70%;
//     padding-left: 20px
// }

// .cco_col_12 {
//     width: 100%
// }



// .cco_text_0 {
//     color: #92929d;
//     font-family: HKGrotesk Regular;
//     font-size: 18px
// }

// .cco_text_0 strong {
//     color: #171725;
//     font-family: DMSans Bold;
//     font-size: 24px;
//     line-height: 1.3
// }

// .cco_text_1 {
//     margin-bottom: 10px
// }

// .cco_text_1 strong {
//     color: #000;
//     font-family: DMSans Medium;
//     font-weight: 500
// }

// .cco_text_1 h1 {
//     font-size: 32px
// }

// .cco_text_1 h1 small {
//     color: #666;
//     display: inline-block;
//     font-family: DMSans Regular;
//     font-size: 14px;
//     font-weight: 400;
//     position: relative;
//     top: -4px
// }

// .cco_text_1 .divider {
//     margin-left: 20px
// }

// .cco_text_2:nth-last-child(n+2) {
//     margin-bottom: 30px
// }

// .cco_text_2 h2 {
//     font-size: 20px
// }

// .cco_text_2 h2 small {
//     color: #666;
//     display: inline-block;
//     font-family: DMSans Regular;
//     font-size: 14px;
//     font-weight: 400
// }

// .cco_text_3 {
//     font-family: HKGrotesk Regular;
//     font-size: 14px;
//     line-height: 1.3
// }

// .cco_text_3 h3 {
//     font-family: DMSans Medium;
//     font-size: 16px;
//     font-weight: 500
// }

// .cco_text_3 .success {
//     color: #3dd598
// }

// .cco_text_3 .error {
//     color: #db3a3a
// }

// .cco_text_4 {
//     background-color: #fafafb;
//     font-family: DMSans Medium;
//     font-size: 10px;
//     font-weight: 500;
//     line-height: 1.4;
//     padding: 10px 15px;
//     text-transform: uppercase
// }

// .cco_text_4:nth-child(n+2) {
//     border-top: 1px solid #e2e2ea
// }

// .cco_text_5 {
//     margin-bottom: 40px
// }

// .cco_text_5 h1 {
//     font-size: 24px
// }

// .cco_text_6 {
//     font-size: 18px;
//     margin-bottom: 10px
// }

// .cco_text_7 {
//     font-size: 10px
// }

// .cco_text_8 {
//     font-family: DMSans Medium;
//     font-weight: 500;
//     margin-bottom: 20px
// }

// .cco_text_9 {
//     font-size: 14px
// }

// .cco_text_9 h3 {
//     font-size: 18px
// }

// .cco_text_10 {
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     justify-content: center
// }

// .cco_text_10 h2 {
//     font-size: 20px
// }

// .cco_text_10 h2 small {
//     color: #666;
//     display: inline-block;
//     font-family: DMSans Regular;
//     font-size: 14px;
//     font-weight: 400
// }

// .cco_text_11 {
//     color: #171725;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     line-height: 1.3
// }


// strong {
//     font-weight: 700;
// }



// .cco_text_13 h1 {
//     color: #171725;
//     font-size: 32px
// }

// .cco_text_14 {
//     background-color: #fff;
//     border-radius: 15px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     padding: 25px 20px 30px
// }

// .cco_text_15 {
//     color: #171725
// }

// .cco_table {
//     font-size: 14px;
//     line-height: 1.6
// }

// .cco_table,.cco_table_body {
//     display: block
// }

// .cco_table_row {
//     display: flex;
//     margin-left: -20px;
//     margin-right: -20px
// }

// .cco_table_cell {
//     padding: 0 20px;
//     width: 33.33%
// }

// .cco_table_cell_small {
//     width: 30%
// }

// .cco_table_cell_medium {
//     width: 40%
// }

// .cco_table_cell_large {
//     width: 66.66%
// }

// @media (max-width: 980px) {
//     .cco_table_row {
//         flex-direction:column;
//         margin-left: 0;
//         margin-right: 0
//     }

//     .cco_table_cell {
//         padding-left: 0;
//         padding-right: 0;
//         width: 100%
//     }

//     .cco_table_cell_large {
//         width: 100%
//     }
// }

// .cco_table_0 .cco_table_row {
//     flex-direction: column;
//     margin-left: 0;
//     margin-right: 0
// }

// .cco_table_0 .cco_table_cell {
//     padding-left: 0;
//     padding-right: 0;
//     width: 100%
// }

// .cco_table_0 .cco_table_cell:nth-last-child(n+2) {
//     margin-bottom: 22px
// }

// .cco_table_0 .cco_table_cell_large {
//     width: 100%
// }

// .cco_table_1:nth-last-child(n+2) {
//     border-bottom: 1px solid #e2e2ea;
//     margin-bottom: 20px;
//     padding-bottom: 20px
// }

// .cco_blurb_0 {
//     display: flex;
//     padding-bottom: 8px;
//     padding-top: 8px
// }

// .cco_blurb_0 strong {
//     color: #000;
//     font-family: DMSans Medium;
//     font-weight: 500
// }

// .cco_blurb_0 .cco_blurb_label {
//     min-width: 100px;
//     padding-right: 20px
// }

// .cco_blurb_0 .cco_blurb_value {
//     min-width: 70px;
//     overflow: hidden;
//     text-overflow: ellipsis
// }

// .cco_blurb_0.cco_blurb_has_border {
//     border-bottom: 1px solid #e4e4e2
// }

// .cco_blurb_1 {
//     display: flex;
//     font-size: 14px
// }

// .cco_blurb_1:nth-last-child(n+2) {
//     margin-bottom: 12px
// }

// .cco_blurb_1 strong {
//     color: #000;
//     font-family: DMSans Medium;
//     font-weight: 500
// }

// .cco_blurb_1 .cco_blurb_label {
//     padding-right: 10px;
//     width: 33.33%
// }

// .cco_blurb_1 .cco_blurb_value {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     width: 66.66%
// }

// .cco_blurb_2 {
//     display: flex
// }

// .cco_blurb_2 .cco_blurb_img {
//     background-color: #fff;
//     border-radius: 23px;
//     height: 120px;
//     margin-right: 20px;
//     min-width: 120px;
//     overflow: hidden;
//     position: relative;
//     width: 120px
// }

// .cco_blurb_2 .cco_blurb_img:before {
//     border: 1px dashed #aeaeae;
//     border-radius: 23px;
//     content: "";
//     height: calc(100% - 2px);
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: calc(100% - 2px)
// }

// .cco_blurb_2 .cco_blurb_img img {
//     height: 100%;
//     left: 0;
//     -o-object-fit: cover;
//     object-fit: cover;
//     position: absolute;
//     top: 0;
//     width: 100%
// }

// .cco_blurb_2 .cco_blurb_img_icon {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #aeaeae;
//     font-family: Icomoon;
//     font-size: 40px;
//     font-style: normal;
//     left: calc(50% - 20px);
//     line-height: 1;
//     position: absolute;
//     top: calc(50% - 20px)
// }

// .cco_blurb_2 .cco_blurb_img_icon_camera:before {
//     content: "\e92c"
// }

// .cco_blurb_2 .cco_blurb_img_icon_file:before {
//     content: "\e92b"
// }

// .cco_blurb_2 .cco_blurb_title {
//     color: #000;
//     font-size: 14px;
//     padding-top: 10px;
//     word-break: break-all
// }

// .cco_blurb_3 {
//     color: #000;
//     display: flex;
//     font-family: DMSans Regular;
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 1.3
// }

// .cco_blurb_3:nth-child(n+2) {
//     margin-top: 12px
// }

// .cco_blurb_3:before {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     font-family: Icomoon;
//     font-style: normal;
//     line-height: 14px;
//     min-width: 17px;
//     padding-right: 8px;
//     padding-top: 2px
// }

// .cco_blurb_3.cco_blurb_success:before {
//     color: #3dd598;
//     content: "\e920";
//     font-size: 17px
// }

// .cco_blurb_3.cco_blurb_error:before {
//     color: #db3a3a;
//     content: "\e919";
//     font-size: 12px
// }

// .cco_blurb_4 {
//     background-color: #fff;
//     border-radius: 15px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     color: #000;
//     display: flex;
//     font-family: DMSans Medium;
//     font-size: 16px;
//     font-weight: 500;
//     padding: 20px
// }

// .cco_blurb_4 .cco_blurb_main {
//     flex-grow: 1;
//     padding-right: 40px
// }

// .cco_blurb_4 .cco_blurb_aside {
//     display: flex;
//     flex-direction: column;
//     justify-content: center
// }

// .cco_blurb_5 {
//     display: flex;
//     justify-content: flex-end;
//     min-width: 80px
// }

// .cco_blurb_5:after {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #3dd598;
//     content: "\e920";
//     font-family: Icomoon;
//     font-size: 17px;
//     font-style: normal;
//     line-height: 14px;
//     min-width: 17px;
//     padding-left: 8px;
//     padding-top: 2px
// }

// .cco_menu_0 {
//     display: flex;
//     margin-left: -9px;
//     margin-right: -9px;
//     list-style: none;

// }


// .cco_menu_0 .cco_menu_item {
//     margin-bottom: 15px;
//     padding-left: 9px;
//     padding-right: 9px
// }

// .cco_menu_item  a{
// text-decoration: none;
// }

// .cco_menu_0 .cco_menu_item a {
//     align-items: center;
//     background-color: #fff;
//     border: 1px solid #c67b05;
//     border-radius: 21px;
//     color: #c67b05;
//     display: flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     height: 100%;
//     justify-content: center;
//     line-height: 1.3;
//     min-height: 42px;
//     padding: 8px 29px;
//     text-align: center;
// }

// .cco_menu_0 .cco_menu_item_primary {
//     flex-grow: 1
// }

// .cco_menu_0 .cco_menu_item_primary a {
//     background-color: #c67b05;
//     color: #fff
// }

// @media (max-width: 1199px) {
//     .cco_menu_0 .cco_menu_item a {
//         font-size:16px
//     }
// }

// @media (max-width: 980px) {
//     .cco_menu_0 {
//         flex-wrap:wrap
//     }

//     .cco_menu_0 .cco_menu_item {
//         width: 50%
//     }
// }

// @media (max-width: 767px) {
//     .cco_menu_0 {
//         margin-left:0;
//         margin-right: 0
//     }

//     .cco_menu_0 .cco_menu_item {
//         padding-left: 0;
//         padding-right: 0;
//         width: 100%
//     }

//     .cco_menu_0 .cco_menu_item:last-child {
//         margin-bottom: 0
//     }
// }

// .cco_dropdown_content ul {
// margin-bottom: 0px !important;
//     list-style: none !important;
//     padding-left: 0px !important;
//     margin-top: 0px;
// }

// .cco_menu_1 .cco_menu_item a {
//     border-top: 1px solid #e2e2ea;
//     color: #000;
//     display: block;
//     font-size: 13px;
//     line-height: 1.3;
//     padding: 10px 15px 10px 37px;
//     position: relative;
//     text-decoration: none;
// }

// .cco_menu_1 .cco_menu_item a:before {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #666;
//     content: "\e906";
//     font-family: Icomoon;
//     font-size: 14px;
//     font-style: normal;
//     left: 15px;
//     line-height: 1;
//     position: absolute;
//     top: calc(50% - 7px)
// }

// .cco_menu_1 .cco_menu_item a:hover {
//     background-color: #fefcf4
// }

// .cco_menu_1 .cco_menu_item.cco_menu_item_icon_gear a:before {
//     content: "\e90f"
// }

// .cco_menu_1 .cco_menu_item.cco_menu_item_icon_logout a:before {
//     content: "\e90e"
// }

// .cco_menu_1 .cco_menu_item.cco_menu_item_icon_avatar a:before {
//     content: "\e910"
// }

// .cco_menu_1:first-child .cco_menu_item:first-child a {
//     border-top: 0
// }

// .cco_menu_2 {
//     display: flex;
//     flex-wrap: wrap;
//     margin-top: 5px
// }

// .cco_menu_2 .cco_menu_item {
//     margin-bottom: 5px
// }

// .cco_menu_2 .cco_menu_item:nth-last-child(n+2) {
//     margin-right: 10px
// }

// .cco_menu_2 .cco_menu_item a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 1px solid #c67b05;
//     border-radius: 5px;
//     color: #fff;
//     display: flex;
//     flex-direction: column;
//     font-size: 14px;
//     font-weight: 700;
//     justify-content: center;
//     line-height: 1.3;
//     min-height: 26px;
//     min-width: 70px;
//     padding: 2px 6px;
//     text-align: center;
//     white-space: nowrap
// }

// .cco_menu_3 {
//     display: flex;
//     flex-wrap: wrap;
//     margin-bottom: -15px;
//     margin-left: -9px;
//     margin-right: -9px
// }

// .cco_menu_3 .cco_menu_item {
//     margin-bottom: 15px;
//     padding-left: 9px;
//     padding-right: 9px
// }

// .cco_menu_3 .cco_menu_item a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 1px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     display: flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     height: 100%;
//     justify-content: center;
//     line-height: 1.3;
//     min-height: 42px;
//     min-width: 166px;
//     padding: 8px 17px;
//     text-align: center
// }

// .cco_menu_3 .cco_menu_item:first-child a {
//     background-color: #fff;
//     color: #c67b05
// }

// @media (max-width: 1199px) {
//     .cco_menu_3 .cco_menu_item a {
//         font-size:16px
//     }
// }

// @media (max-width: 767px) {
//     .cco_menu_3 {
//         margin-bottom:0;
//         margin-left: 0;
//         margin-right: 0
//     }

//     .cco_menu_3 .cco_menu_item {
//         padding-left: 0;
//         padding-right: 0;
//         width: 100%
//     }

//     .cco_menu_3 .cco_menu_item a {
//         min-width: 0
//     }

//     .cco_menu_3 .cco_menu_item:last-child {
//         margin-bottom: 0
//     }
// }

// .cco_menu_4 {
//     border: 1px dashed #e2e2ea;
//     border-radius: 20px;
//     display: flex;
//     padding: 17px 11px
// }

// .cco_menu_4 .cco_menu_item {
//     flex-grow: 1;
//     padding-left: 9px;
//     padding-right: 9px
// }

// .cco_menu_4 .cco_menu_item a {
//     align-items: center;
//     color: #666;
//     display: inline-flex;
//     line-height: 1.3;
//     min-height: 38px;
//     padding: 3px 20px
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon a {
//     position: relative
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon a:before {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     background-color: #fff;
//     border-radius: 50%;
//     color: #c67b05;
//     font-family: Icomoon;
//     font-size: 15px;
//     font-style: normal;
//     line-height: 1;
//     padding: 11.5px;
//     position: absolute;
//     top: calc(50% - 19px)
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon_plus a:before {
//     content: "\e904"
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon_close a:before {
//     content: "\e919"
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon_caret_left a:before {
//     content: "\e929"
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon_caret_right a:before {
//     content: "\e928"
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon_checkmark a:before {
//     content: "\e920"
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon:first-child a,.cco_menu_4 .cco_menu_item.cco_menu_item_icon:nth-last-child(n+2) a {
//     padding-left: 46px
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon:first-child a:before,.cco_menu_4 .cco_menu_item.cco_menu_item_icon:nth-last-child(n+2) a:before {
//     left: 0
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon:nth-child(n+2) {
//     text-align: center
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child {
//     text-align: right
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child a {
//     padding-right: 46px
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child a:before {
//     right: 0
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_btn a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 2px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     display: inline-flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     justify-content: center;
//     line-height: 1.3;
//     max-width: 100%;
//     min-height: 42px;
//     padding: 7px 16px;
//     text-align: center;
//     width: 296px
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_btn:last-child {
//     text-align: right
// }

// .cco_menu_4 .cco_menu_item.cco_menu_item_btn_disabled a {
//     cursor: not-allowed;
//     opacity: .5
// }

// @media (max-width: 1199px) {
//     .cco_menu_4 .cco_menu_item.cco_menu_item_btn a {
//         font-size:16px
//     }
// }

// @media (max-width: 767px) {
//     .cco_menu_4 {
//         flex-wrap:wrap;
//         margin-bottom: 0;
//         margin-left: 0;
//         margin-right: 0
//     }

//     .cco_menu_4 .cco_menu_item {
//         padding-left: 0;
//         padding-right: 0;
//         width: 100%
//     }

//     .cco_menu_4 .cco_menu_item:nth-last-child(n+2) {
//         margin-bottom: 15px
//     }

//     .cco_menu_4 .cco_menu_item a {
//         font-size: 14px
//     }

//     .cco_menu_4 .cco_menu_item.cco_menu_item_icon a:before {
//         box-shadow: 0 10px 30px rgba(0,0,0,.05)
//     }

//     .cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child,.cco_menu_4 .cco_menu_item.cco_menu_item_icon:nth-child(n+2) {
//         text-align: left
//     }

//     .cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child a {
//         padding-left: 46px;
//         padding-right: 20px
//     }

//     .cco_menu_4 .cco_menu_item.cco_menu_item_icon:not(:first-child):last-child a:before {
//         left: 0;
//         right: auto
//     }

//     .cco_menu_4 .cco_menu_item.cco_menu_item_btn a {
//         width: 100%
//     }
// }

// .cco_menu_5 .cco_menu_item:nth-last-child(n+2) {
//     margin-bottom: 20px;
  
    
// }
// .cco_menu_5 .cco_menu_item {
// list-style: none;

// }


// .cco_menu_5 .cco_menu_item.cco_menu_item_active a {
//     color: #c67b05;
//     font-family: DMSans Bold;
//     font-weight: 700
// }

// .cco_menu_5 .cco_menu_item.cco_menu_item_active a:after {
//     background-color: #c67b05
// }

// .cco_menu_5 .cco_menu_item.cco_menu_item_icon_dashboard a:before {
//     content: "\e90b"
// }

// .cco_menu_5 .cco_menu_item.cco_menu_item_icon_certificate a:before {
//     content: "\e90a"
// }

// .cco_menu_5 .cco_menu_item.cco_menu_item_icon_paper a:before {
//     content: "\e909"
// }

// .cco_menu_5 .cco_menu_item.cco_menu_item_icon_paper_plane a:before {
//     content: "\e908"
// }

// .cco_menu_5 .cco_menu_item.cco_menu_item_icon_chatting a:before {
//     content: "\e907"
// }



// .cco_menu_6 {
//     display: flex;
//     flex-wrap: wrap;
//     margin-bottom: -15px;
//     margin-left: -9px;
//     margin-right: -9px
// }

// .cco_menu_6 .cco_menu_item {
//     margin-bottom: 15px;
//     padding-left: 9px;
//     padding-right: 9px;
//     width: 50%
// }

// .cco_menu_6 .cco_menu_item:first-child:last-child {
//     width: 100%
// }

// .cco_menu_6 .cco_menu_item a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 1px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     display: flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     height: 100%;
//     justify-content: center;
//     line-height: 1.3;
//     min-height: 42px;
//     padding: 8px 17px;
//     text-align: center
// }

// .cco_menu_6 .cco_menu_item:not(:last-child):nth-child(odd) a {
//     background-color: #fff;
//     color: #c67b05
// }

// @media (max-width: 1199px) {
//     .cco_menu_6 .cco_menu_item a {
//         font-size:16px
//     }
// }

// @media (max-width: 479px) {
//     .cco_menu_6 {
//         margin-bottom:0;
//         margin-left: 0;
//         margin-right: 0
//     }

//     .cco_menu_6 .cco_menu_item {
//         padding-left: 0;
//         padding-right: 0;
//         width: 100%
//     }

//     .cco_menu_6 .cco_menu_item:last-child {
//         margin-bottom: 0
//     }
// }

// .cco_menu_7 {
//     display: flex;
//     margin-left: -9px;
//     margin-right: -9px
// }

// .cco_menu_7 .cco_menu_item {
//     padding-left: 9px;
//     padding-right: 9px
// }

// .cco_menu_7 .cco_menu_item a {
//     align-items: center;
//     background-color: #fff;
//     border: 2px solid #c67b05;
//     border-radius: 21px;
//     color: #c67b05;
//     display: flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     justify-content: center;
//     line-height: 1.3;
//     max-width: 100%;
//     min-height: 42px;
//     padding: 7px 16px;
//     text-align: center;
//     width: 296px
// }

// @media (max-width: 1199px) {
//     .cco_menu_7 .cco_menu_item a {
//         font-size:16px
//     }
// }

// @media (max-width: 767px) {
//     .cco_menu_7 {
//         flex-wrap:wrap;
//         margin-left: 0;
//         margin-right: 0
//     }

//     .cco_menu_7 .cco_menu_item {
//         padding-left: 0;
//         padding-right: 0;
//         width: 100%
//     }

//     .cco_menu_7 .cco_menu_item:nth-last-child(n+2) {
//         margin-bottom: 15px
//     }

//     .cco_menu_7 .cco_menu_item a {
//         width: 100%
//     }
// }

// .cco_accordion:nth-last-child(n+2) {
//     margin-bottom: 25px
// }

// .cco_accordion_item {
//     background-color: #fff;
//     border-radius: 15px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     padding: 20px
// }

// .cco_accordion_item:nth-last-child(n+2) {
//     margin-bottom: 25px
// }

// .cco_accordion_item_header {
//     min-height: 50px;
//     padding-right: 140px;
//     position: relative
// }

// .cco_accordion_item_header,.cco_accordion_item_toggle {
//     display: flex;
//     flex-direction: column;
//     justify-content: center
// }

// .cco_accordion_item_toggle {
//     align-items: center;
//     background-color: #c67b05;
//     border: 2px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     cursor: pointer;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     line-height: 1.3;
//     min-height: 42px;
//     padding: 7px 16px;
//     position: absolute;
//     right: 0;
//     text-align: center;
//     top: 4px;
//     width: 100px
// }

// .cco_accordion_item_body {
//     display: none
// }

// .cco_accordion_item_body_inner {
//     border-top: 1px solid #e4e4e2;
//     margin-top: 20px;
//     padding-top: 20px
// }

// .cco_accordion_item.active .cco_accordion_item_toggle:before {
//     transform: scale(-1)
// }

// .cco_accordion_item.active .cco_accordion_item_body {
//     display: block
// }

// @media (max-width: 1199px) {
//     .cco_accordion_item_toggle {
//         font-size:16px
//     }
// }

// @media (max-width: 767px) {
//     .cco_accordion_item_header {
//         min-height:24px;
//         padding-right: 43px
//     }

//     .cco_accordion_item_toggle {
//         background-color: transparent;
//         border: 0;
//         border-radius: 0;
//         color: #c67b05;
//         font-size: 14px;
//         font-weight: 400;
//         line-height: 1;
//         min-height: 0;
//         padding: 7px;
//         right: -5px;
//         top: -2px;
//         width: auto
//     }

//     .cco_accordion_item_toggle:before {
//         speak: never;
//         -webkit-font-smoothing: antialiased;
//         -moz-osx-font-smoothing: grayscale;
//         content: "\e911";
//         font-family: Icomoon;
//         font-style: normal
//     }

//     .cco_accordion_item_toggle_text {
//         display: none
//     }
// }


// .cco_dropdown_0 {
//     margin-left: -7px;
//     margin-right: -7px;
//     position: relative
// }

// .cco_dropdown_0 .cco_dropdown_toggle {
//     align-items: center;
//     background-color: transparent;
//     border: 0;
//     cursor: pointer;
//     display: flex;
//     margin-left: auto;
//     max-width: 100%;
//     min-height: 40px;
//     padding: 7px 35px 7px 49px;
//     position: relative
// }

// .cco_dropdown_0 .cco_dropdown_toggle_img {
//     background-color: #fffefc;
//     border-radius: 50%;
//     height: 32px;
//     left: 7px;
//     -o-object-fit: cover;
//     object-fit: cover;
//     position: absolute;
//     top: calc(50% - 16px);
//     width: 46px
// }

// .cco_dropdown_0 .cco_dropdown_toggle_text {
//     color: #000;
//     display: block;
//     font-family: DMSans Bold;
//     font-size: 14px;
//     font-weight: 700;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     width: 100%
// }

// .cco_dropdown_0 .cco_dropdown_toggle:after {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #dfbd26;
//     content: "\e912";
//     font-family: Icomoon;
//     font-size: 10px;
//     font-style: normal;
//     line-height: 1;
//     position: absolute;
//     right: 7px;
//     top: calc(50% - 4px)
// }

// .cco_dropdown_0 .cco_dropdown_content {
//     background-color: #fff;
//     border: 1px solid #e2e2ea;
//     border-radius: 5px;
//     box-sizing: 0 0 30px rgba(0,0,0,.1);
//     display: none;
//     overflow: hidden;
//     position: absolute;
//     right: 7px;
//     top: calc(100% + 4px);
//     width: 250px;
//     z-index: 100
// }

// .cco_dropdown_0.active .cco_dropdown_toggle:after {
//     transform: scale(-1)
// }

// .cco_dropdown_0.active .cco_dropdown_content {
//     display: block
// }

// @media (max-width: 980px) {
//     .cco_dropdown_0 .cco_dropdown_toggle {
//         padding:4px 7px
//     }

//     .cco_dropdown_0 .cco_dropdown_toggle_img {
//         position: static
//     }

//     .cco_dropdown_0 .cco_dropdown_toggle:after,.cco_dropdown_0 .cco_dropdown_toggle_text {
//         display: none
//     }
// }



// .cco_btn_0 {
//     text-align: right
// }

// .cco_btn_0 a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 2px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     display: inline-flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-weight: 700;
//     height: 100%;
//     justify-content: center;
//     line-height: 1.3;
//     min-height: 40px;
//     padding: 7px 16px;
//     text-align: center
// }

// .cco_btn_1 {
//     margin-bottom: 20px
// }

// .cco_btn_1 a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 1px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     display: inline-flex;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     height: 100%;
//     justify-content: center;
//     line-height: 1.3;
//     max-width: 100%;
//     min-height: 42px;
//     padding: 8px 17px;
//     text-align: center;
//     width: 296px
// }

// .cco_btn_1 a:after {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #fff;
//     content: "\e92a";
//     font-family: Icomoon;
//     font-size: 12px;
//     font-style: normal;
//     line-height: 1;
//     margin-left: 6px
// }

// .cco_btn_2 {
//     border-top: 1px solid #e2e2ea;
//     margin-top: 20px;
//     padding-top: 20px
// }

// .cco_btn_2 a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 1px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     display: inline-flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     height: 100%;
//     line-height: 1.3;
//     min-height: 42px;
//     min-width: 100px;
//     padding: 8px 17px;
//     text-align: center
// }

// .cco_btn_3 {
//     text-align: right
// }

// .cco_btn_3 a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 2px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     display: inline-flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-weight: 700;
//     height: 100%;
//     justify-content: center;
//     line-height: 1.3;
//     min-height: 30px;
//     padding: 7px 16px;
//     text-align: center;
//     text-decoration: none;
// }

// @media (max-width: 980px) {
//     .cco_btn_3 {
//         text-align:left
//     }
// }

// .cco_btn_4 {
//     margin-left: -8px;
//     margin-right: -8px
// }

// .cco_btn_4 button {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     background-color: transparent;
//     border: 0;
//     color: #666;
//     cursor: pointer;
//     padding: 8px
// }

// .cco_btn_4 button:before {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     content: "\e918";
//     display: block;
//     font-family: Icomoon;
//     font-size: 24px;
//     font-style: normal;
//     line-height: 1
// }

// .cco_btn_4 button span {
//     display: none
// }

// .cco_btn_5 a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 2px solid #c67b05;
//     border-radius: 21px;
//     color: #fff!important;
//     display: inline-flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-weight: 700;
//     height: 100%;
//     justify-content: center;
//     line-height: 1.3;
//     min-height: 40px;
//     padding: 7px 16px;
//     text-align: center
// }

// .cco_btn_6 {
//     border-top: 1px solid #e2e2ea;
//     margin-top: 20px;
//     padding-top: 20px
// }

// .cco_btn_6 a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 1px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     // display: inline-flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     height: 100%;
//     line-height: 1.3;
//     min-height: 42px;
//     min-width: 100px;
//     padding: 8px 29px;
//     text-align: center;
//     text-decoration: none;
// }

// h1,h2,h3,h4,h5,h6 {
//     font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif
// }

// body,input,textarea,select {
//     font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
// }

// @media (max-width: 1199px) {
//     .cco_btn_6 a {
//         font-size:16px
//     }
// }

// @media (max-width: 479px) {
//     .cco_btn_6 a {
//         width:100%
//     }
// }

// .ccs_loader {
//     align-items: center;
//     display: flex;
//     height: 40px;
//     justify-content: center;
//     position: relative;
//     width: 100%
// }

// .ccs_loader_fieldset {
//     position: absolute;
//     right: 40px;
//     top: 0;
//     width: auto
// }

// .ccs_loader_container {
//     align-items: center;
//     background-color: #fff;
//     bottom: 0;
//     display: flex;
//     justify-content: center;
//     left: 0;
//     opacity: .8;
//     position: absolute;
//     right: 0;
//     top: 0
// }

// .ccs_loader_relative_container {
//     position: relative
// }

// .ccs_loader_item {
//     -webkit-animation: ccoLoad 1.2s cubic-bezier(.5,0,.5,1) infinite;
//     animation: ccoLoad 1.2s cubic-bezier(.5,0,.5,1) infinite;
//     border: 4px solid transparent;
//     border-radius: 50%;
//     border-top-color: #c67b05;
//     box-sizing: border-box;
//     display: block;
//     height: 32px;
//     margin: 4px;
//     position: absolute;
//     width: 32px
// }

// .ccs_loader_item:first-child {
//     -webkit-animation-delay: -.45s;
//     animation-delay: -.45s
// }

// .ccs_loader_item:nth-child(2) {
//     -webkit-animation-delay: -.3s;
//     animation-delay: -.3s
// }

// .ccs_loader_item:nth-child(3) {
//     -webkit-animation-delay: -.15s;
//     animation-delay: -.15s
// }

// @-webkit-keyframes ccoLoad {
//     0% {
//         transform: rotate(0deg)
//     }

//     to {
//         transform: rotate(1turn)
//     }
// }

// @keyframes ccoLoad {
//     0% {
//         transform: rotate(0deg)
//     }

//     to {
//         transform: rotate(1turn)
//     }
// }

// .cco_form label,.cco_form_label {
//     color: #666;
//     cursor: default;
//     display: inline-block;
//     font-family: DMSans Medium;
//     font-weight: 500;
//     line-height: 1.3;
//     margin-bottom: 12px
// }

// .cco_form label small,.cco_form_label small {
//     color: #92929d;
//     font-family: HKGrotesk Regular;
//     font-size: 12px;
//     font-weight: 400
// }

// .cco_form input:not([type=file]):not([type=checkbox]),.cco_form select,.cco_form textarea {
//     background-color: #fff;
//     border: 0;
//     border-radius: 15px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     color: #666;
//     display: block;
//     font-size: 16px;
//     line-height: 1.3;
//     min-height: 60px;
//     padding: 18px 20px;
//     width: 100%
// }

// .cco_form input:not([type=file]):not([type=checkbox]):disabled,.cco_form select:disabled,.cco_form textarea:disabled {
//     background-color: #fefcf4;
//     cursor: not-allowed
// }

// .cco_form select {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     background-image: url('../../src/assets/icons/Caret_Down_2.svg');
//     background-position: center right 20px;
//     background-repeat: no-repeat;
//     background-size: 12px;
//     cursor: pointer;
//     padding-right: 42px
// }

// .cco_form button:not(.cco_collapse_toggle) {
//     align-items: center;
//     background-color: #c67b05;
//     border: 2px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     cursor: pointer;
//     display: flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     font-weight: 700;
//     justify-content: center;
//     line-height: 1.3;
//     max-width: 100%;
//     min-height: 42px;
//     padding: 7px 16px;
//     text-align: center;
//     width: 296px
// }

// .cco_form_checkbox {
//     align-items: center;
//     display: inline-flex!important;
//     margin-bottom: 0!important;
//     min-height: 60px;
//     padding-left: 80px;
//     position: relative
// }

// .cco_form_checkbox_input {
//     position: absolute;
//     visibility: hidden
// }

// .cco_form_checkbox_checkmark {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     background-color: #fff;
//     border-radius: 15px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     color: #c67b1d;
//     cursor: pointer;
//     font-family: Icomoon;
//     font-size: 20px;
//     font-style: normal;
//     left: 0;
//     line-height: 1;
//     padding: 20px;
//     position: absolute;
//     top: 0
// }

// .cco_form_checkbox_checkmark:before {
//     content: "\e920";
//     visibility: hidden
// }

// .cco_form_checkbox_input:checked~.cco_form_checkbox_checkmark:before {
//     visibility: visible
// }

// .cco_form_checkbox_0 {
//     min-height: 38px;
//     padding-left: 48px
// }

// .cco_form_checkbox_0 .cco_form_checkbox_checkmark {
//     border-radius: 10px;
//     padding: 9px
// }

// .cco_form_heading {
//     color: #666;
//     font-size: 20px;
//     padding-bottom: 20px
// }

// .cco_form_subheading {
//     font-family: DMSans Medium;
//     font-weight: 500;
//     padding-bottom: 20px!important
// }

// .cco_form_note {
//     font-size: 14px;
//     margin-top: 12px
// }

// .cco_form_note a {
//     color: #c67b05
// }

// .cco_form_note strong {
//     font-family: DMSans Medium;
//     font-weight: 500
// }

// .cco_form_fieldset {
//     display: flex;
//     flex-wrap: wrap;
//     margin: -12.5px
// }

// .cco_form_fieldset:nth-last-child(n+2) {
//     margin-bottom: 12.5px
// }




// .cco_form_fieldset_1 .cco_form_fieldset_item:nth-child(n+2) {
//     padding-left: 10px
// }

// .cco_form_fieldset_2 {
//     flex-direction: column;
//     margin: 0
// }

// .cco_form_fieldset_2:nth-last-child(n+2) {
//     margin-bottom: 24px
// }

// .cco_form_fieldset_2 .cco_form_fieldset_item {
//     padding: 0
// }

// .cco_form_fieldset_2 .cco_form_fieldset_item:nth-last-child(n+2) {
//     margin-bottom: 24px
// }

// .cco_form_message {
//     line-height: 1.3
// }

// .cco_form_message:nth-child(n+2) {
//     margin-top: 6px
// }

// .cco_form_message_success {
//     color: #3dd598
// }

// .cco_form_message_error {
//     color: #db3a3a
// }

// .cco_form_message_error_footer {
//     display: block!important;
//     margin-bottom: 20px;
//     text-align: right
// }

// .cco_form_message_has_icon {
//     display: flex
// }

// .cco_form_message_has_icon:before {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     display: inline-block;
//     font-family: Icomoon;
//     font-style: normal;
//     line-height: 14px;
//     min-width: 28px;
//     padding-right: 8px;
//     padding-top: 3px
// }

// .cco_form_message_has_icon.cco_form_message_success:before {
//     content: "\e920";
//     font-size: 19px
// }

// .cco_form_message_has_icon.cco_form_message_error:before {
//     content: "\e919";
//     font-size: 14px
// }

// .cco_form_wrap {
//     border: 1px solid #e2e2ea;
//     border-radius: 23px;
//     padding: 20px 25px 25px
// }

// .cco_form_row {
//     display: flex;
//     flex-wrap: wrap
// }

// .cco_form_row:nth-last-child(n+2) {
//     margin-bottom: 40px
// }

// .cco_form_row_0 {
//     margin-left: -12.5px;
//     margin-right: -12.5px
// }

// .cco_form_col {
//     width: 100%
// }

// .cco_form_col_0 {
//     width: calc(50% - 12.5px)
// }

// .cco_form_col_1 {
//     padding-right: 12.5px;
//     width: 50%
// }

// .cco_form_col_2 {
//     padding-left: 12.5px;
//     width: 50%
// }

// .cco_form_col_3 {
//     padding-left: 12.5px;
//     padding-right: 12.5px;
//     width: 33.33%
// }

// .cco_form_field_0 {
//     display: flex
// }

// .cco_form_field_0 .cco_form_field_body {
//     position: relative
// }

// .cco_form_field_0 .cco_form_field_aside {
//     flex-grow: 1;
//     padding-left: 20px;
//     padding-top: 10px
// }

// .cco_form_field_0 .cco_form_field_label {
//     background-color: #fff;
//     border-radius: 23px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     cursor: pointer;
//     display: block;
//     height: 185px;
//     margin-bottom: 0;
//     overflow: hidden;
//     position: relative;
//     width: 185px
// }

// .cco_form_field_0 .cco_form_field_label:before {
//     border: 1px dashed #aeaeae;
//     border-radius: 23px;
//     content: "";
//     height: calc(100% - 2px);
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: calc(100% - 2px)
// }

// .cco_form_field_0 .cco_form_field_label img {
//     height: 100%;
//     left: 0;
//     -o-object-fit: cover;
//     object-fit: cover;
//     position: absolute;
//     top: 0;
//     width: 100%
// }

// .cco_form_field_0 .cco_form_field_label_icon {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #aeaeae;
//     font-family: Icomoon;
//     font-size: 56px;
//     font-style: normal;
//     left: calc(50% - 28px);
//     line-height: 1;
//     position: absolute;
//     top: calc(50% - 44px)
// }

// .cco_form_field_0 .cco_form_field_label_icon_profile:before {
//     content: "\e91a"
// }

// .cco_form_field_0 .cco_form_field_label_icon_camera:before {
//     content: "\e92c"
// }

// .cco_form_field_0 .cco_form_field_label_icon_file:before {
//     content: "\e92b"
// }

// .cco_form_field_0 .cco_form_field_label_caption {
//     background-color: hsla(0,0%,68%,.5);
//     border-bottom-left-radius: 23px;
//     border-bottom-right-radius: 23px;
//     bottom: 0;
//     color: #fff;
//     font-family: HKGrotesk Regular;
//     left: 0;
//     letter-spacing: .4px;
//     line-height: 1.5;
//     padding: 4px 10px;
//     position: absolute;
//     text-align: center;
//     width: 100%
// }

// .cco_form_field_0 .cco_form_field_label_caption_hover {
//     transform: translateY(100%);
//     transition: all .15s ease-in-out
// }

// .cco_form_field_0 .cco_form_field_label:hover .cco_form_field_label_caption_hover {
//     transform: translateY(0)
// }

// .cco_form_field_0 .cco_form_field_input {
//     position: absolute;
//     visibility: hidden
// }

// .cco_form_field_1 {
//     background-color: #f7f7f7!important;
//     background-image: url('../../src/assets/icons/Loupe_1.svg');
//     background-position: center left 15px;
//     background-repeat: no-repeat;
//     background-size: 16px;
//     box-shadow: none!important;
//     font-size: 14px!important;
//     min-height: 0!important;
//     padding: 12px 20px 12px 41px!important
// }

// .cco_form_field_1::-webkit-input-placeholder {
//     color: #aeaeae
// }

// .cco_form_field_1::-moz-placeholder {
//     color: #aeaeae
// }

// .cco_form_field_1:-ms-input-placeholder {
//     color: #aeaeae
// }

// .cco_form_field_1:-moz-placeholder {
//     color: #aeaeae
// }

// .cco_form_card {
//     background-color: #fff;
//     border-radius: 15px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     padding: 20px 20px 25px
// }

// .cco_form_card_body:nth-child(n+2) {
//     margin-top: 25px
// }

// .cco_form_btn_0 {
//     align-items: center!important;
//     border-radius: 13px!important;
//     border-width: 1px!important;
//     font-family: DMSans Regular!important;
//     font-weight: 400!important;
//     justify-content: center!important;
//     padding: 3px 6px!important;
//     text-align: center!important;
//     width: 100px!important
// }

// .cco_form_btn_0,.cco_form_btn_1 {
//     display: flex!important;
//     font-size: 14px!important;
//     line-height: 1.3!important;
//     min-height: 26px!important
// }

// .cco_form_btn_1 {
//     background-color: transparent!important;
//     border: 0!important;
//     border-radius: 0!important;
//     padding: 4px 7px!important;
//     width: auto!important
// }

// .cco_form_btn_1:before {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #db3a3a;
//     content: "\e919";
//     font-family: Icomoon;
//     font-style: normal
// }

// .cco_form_btn_2 {
//     margin-top: 24px
// }

// .cco_form_btn_2 a {
//     align-items: center;
//     background-color: #c67b05;
//     border: 2px solid #c67b05;
//     border-radius: 21px;
//     color: #fff;
//     display: inline-flex;
//     flex-direction: column;
//     font-family: DMSans Bold;
//     font-weight: 700;
//     height: 100%;
//     justify-content: center;
//     line-height: 1.3;
//     min-height: 40px;
//     padding: 7px 16px;
//     text-align: center
// }

// .cco_form_blurb_0 {
//     display: flex;
//     font-size: 14px;
//     justify-content: center;
//     max-width: 100%;
//     width: 100px
// }

// .cco_form_blurb_0:after {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #3dd598;
//     content: "\e920";
//     font-family: Icomoon;
//     font-size: 17px;
//     font-style: normal;
//     line-height: 14px;
//     min-width: 17px;
//     padding-left: 8px;
//     padding-top: 2px
// }

// .cco_form_img {
//     background-color: #fff;
//     border-radius: 23px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     display: block;
//     height: 185px;
//     -o-object-fit: cover;
//     object-fit: cover;
//     width: 185px
// }

// @media (max-width: 1199px) {
//     .cco_form button:not(.cco_collapse_toggle) {
//         font-size:16px
//     }
// }

// @media (max-width: 980px) {
//     .cco_form_col_0,.cco_form_col_1,.cco_form_col_2 {
//         width:100%
//     }

//     .cco_form_col_1 {
//         margin-bottom: 25px;
//         padding-right: 0
//     }

//     .cco_form_col_2 {
//         padding-left: 0
//     }

//     .cco_form_col_3 {
//         width: 100%
//     }

//     .cco_form_col_3:nth-last-child(n+2) {
//         margin-bottom: 25px
//     }

//     .cco_form_field_0 .cco_form_field_label_caption_hover {
//         display: none
//     }
// }

// @media (max-width: 767px) {
//     .cco_form button:not(.cco_collapse_toggle),.cco_form_fieldset_item_medium,.cco_form_fieldset_item_small {
//         width:100%
//     }

//     .cco_form_fieldset_0 {
//         font-size: 14px
//     }

//     .cco_form_field_0 .cco_form_field_label {
//         height: 140px;
//         width: 140px
//     }

//     .cco_form_field_0 .cco_form_field_label,.cco_form_field_0 .cco_form_field_label:before {
//         border-radius: 15px
//     }

//     .cco_form_field_0 .cco_form_field_label_icon {
//         font-size: 48px;
//         left: calc(50% - 24px);
//         top: calc(50% - 40px)
//     }

//     .cco_form_field_0 .cco_form_field_label_caption {
//         border-bottom-left-radius: 15px;
//         border-bottom-right-radius: 15px;
//         font-size: 14px
//     }

//     .cco_form_img {
//         height: 140px;
//         width: 140px
//     }
// }

// .cco_collapse {
//     background-color: #fff;
//     border-radius: 15px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     padding: 20px
// }

// .cco_collapse:nth-last-child(n+2) {
//     margin-bottom: 25px
// }

// .cco_collapse_header {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     min-height: 24px;
//     padding-right: 43px;
//     position: relative
// }

// .cco_collapse_toggle {
//     background-color: transparent;
//     border: 0;
//     border-radius: 0;
//     color: #c67b05;
//     cursor: pointer;
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 1;
//     min-height: 0;
//     padding: 7px;
//     position: absolute;
//     right: -5px;
//     top: -2px;
//     width: auto
// }

// .cco_collapse_toggle:before {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     content: "\e911";
//     display: block;
//     font-family: Icomoon;
//     font-style: normal
// }

// .cco_collapse_body,.cco_collapse_toggle_text {
//     display: none
// }

// .cco_collapse_body_inner {
//     border-top: 1px solid #e4e4e2;
//     margin-top: 20px;
//     padding-top: 20px
// }

// .cco_collapse.active .cco_collapse_toggle:before {
//     transform: scale(-1)
// }

// .cco_collapse.active .cco_collapse_body {
//     display: block
// }

// [data-tooltip]:before {
//     border-radius: 10px;
//     box-shadow: 2px 2px 1px silver;
//     color: #333;
//     content: attr(data-tooltip);
//     opacity: 0;
//     padding: 10px;
//     position: absolute;
//     transition: all .15s ease
// }

// [data-tooltip]:hover:before {
//     background: #fff;
//     margin-top: -20px;
//     opacity: 1
// }

// .cco_blog {
//     display: flex;
//     flex-wrap: wrap;
//     margin-left: -15px;
//     margin-right: -15px
// }

// .cco_blog_item {
//     margin-bottom: 30px;
//     padding-left: 15px;
//     padding-right: 15px;
//     width: 25%
// }

// .cco_blog_item_wrap {
//     background-color: #eaeaea;
//     border: 1px solid #e2e2ea;
//     border-radius: 20px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     color: inherit;
//     display: block;
//     padding-bottom: 115%;
//     position: relative
// }

// .cco_blog_item_img {
//     border-radius: 20px;
//     height: 100%;
//     left: 0;
//     -o-object-fit: cover;
//     object-fit: cover;
//     position: absolute;
//     top: 0;
//     width: 100%
// }

// .cco_blog_item_content {
//     background-color: #fff;
//     border-radius: 20px;
//     bottom: -1px;
//     left: -1px;
//     padding: 25px 25px 20px;
//     position: absolute;
//     width: calc(100% + 2px)
// }

// .cco_blog_item_title {
//     font-family: DMSans Medium;
//     font-size: 16px;
//     font-weight: 500
// }

// .cco_blog_item_description {
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     display: -webkit-box;
//     font-size: 14px;
//     line-height: 1.3;
//     overflow: hidden
// }

// @media (max-width: 980px) {
//     .cco_blog_item {
//         width:50%
//     }
// }
// .entry-title {
//     font-weight: bold;
//     font-style: normal;
//     text-transform: none;
//     text-decoration: none;
//     line-height: 1.3em;
//     font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
//     font-size: 32px;
// }
// @media (max-width: 479px) {
//     .cco_blog_item {
//         width:100%
//     }
// }

// .cco_sidebar {
//     background-color: #fff;
//     height: 100vh;
//     left: 0;
//     padding-top: 70px;
//     position: fixed;
//     top: 0;
//     width: 250px;
//     z-index: 100
// }

// .cco_sidebar_inner {
//     overflow-y: auto;
//     padding-bottom: 40px;
//     padding-top: 45px;
// }

// .cco_sidebar_overlay {
//     background-color: #171725;
//     height: 100vh;
//     left: 0;
//     opacity: 0;
//     position: fixed;
//     top: 0;
//     transition: all .15s ease-in-out;
//     visibility: hidden;
//     width: 100vw;
//     z-index: 100
// }

// @media (max-width: 1199px) {
//     .cco_sidebar {
//         box-shadow:10px 0 30px rgba(0,0,0,.1);
//         max-width: 90%;
//         transform: translateX(-100%);
//         transition: all .3s ease-in-out;
//         width: 325px
//     }

//     .cco_sidebar_active {
//         transform: translateX(0)
//     }

//     .cco_sidebar_overlay_active {
//         opacity: .3;
//         visibility: visible
//     }
// }

// @media (max-width: 767px) {
//     .cco_sidebar {
//         background-color:#fafafb
//     }
// }


// .cco_card_0 .cco_card_body {
//     display: flex
// }

// .cco_card_0 .cco_card_aside {
//     width: 100px
// }

// .cco_card_0 .cco_card_main {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     padding-left: 40px;
//     width: calc(100% - 84px)
// }

// .cco_card_0 .cco_card_footer {
//     border-top: 1px solid #e4e4e2;
//     margin-top: 20px;
//     padding-top: 20px
// }


// .cco_card_2 {
//     background-color: #fff;
//     border-radius: 10px;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     padding: 25px 40px 30px
// }

// .cco_card_2 .cco_card_body {
//     display: flex
// }

// .cco_card_2 .cco_card_aside {
//     width: 100px
// }

// .cco_card_2 .cco_card_main {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     padding-left: 40px;
//     width: calc(100% - 84px)
// }

// .cco_card_2 .cco_card_footer {
//     border-top: 1px solid #e4e4e2;
//     margin-top: 20px;
//     padding-top: 20px
// }

// @media (max-width: 767px) {
//     .cco_card_2 {
//         padding-left:30px;
//         padding-right: 30px
//     }
// }



// .cco_fieldset_0 {
//     align-items: center;
//     color: #000;
//     display: flex;
//     font-family: DMSans Regular;
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 1.3
// }

// .cco_fieldset_0:nth-child(n+2) {
//     margin-top: 12px
// }

// .cco_fieldset_0 .cco_fieldset_item:nth-child(n+2) {
//     padding-left: 20px
// }

// .cco_fieldset_0 .cco_fieldset_item_large {
//     flex-grow: 1
// }

// .cco_ad {
//     align-items: center;
//     display: none;
//     justify-content: center
// }

// @media (min-width: 768px) {
//     .cco_ad_0 {
//         display:flex
//     }
// }

// @media (max-width: 767px) {
//     .cco_ad_1 {
//         display:flex
//     }
// }

// .cco_menu_a {
//     display: flex;
//     justify-content: center;
//     margin-left: -13px;
//     margin-right: -13px
// }

// .cco_menu_a .cco_menu_a_item {
//     padding-left: 13px;
//     padding-right: 13px
// }

// .cco_menu_a .cco_menu_a_item a {
//     color: #000;
//     display: block;
//     font-size: 14px;
//     line-height: 1.7;
//     padding: 28px 5px 3px;
//     position: relative
// }

// .cco_menu_a .cco_menu_a_item a:before {
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #c67b05;
//     font-family: Icomoon;
//     font-size: 20px;
//     font-style: normal;
//     left: 50%;
//     line-height: 1;
//     position: absolute;
//     top: 0;
//     transform: translateX(-50%)
// }

// .cco_menu_a .cco_menu_a_item.cco_menu_a_item_icon_crane a:before {
//     content: "\e903"
// }

// .cco_menu_a .cco_menu_a_item.cco_menu_a_item_icon_certificate a:before {
//     content: "\e90a"
// }

// .cco_menu_a .cco_menu_a_item.cco_menu_a_item_icon_arrow_up a:before {
//     content: "\e915"
// }

// .cco_menu_a .cco_menu_a_item.cco_menu_a_item_icon_click a:before {
//     content: "\e902"
// }

// @media (max-width: 479px) {
//     .cco_menu_a .cco_menu_a_item {
//         padding-left:5px;
//         padding-right: 5px
//     }

//     .cco_menu_a {
//         justify-content: space-around;
//         margin-left: -5px;
//         margin-right: -5px
//     }
// }

// .cco_menu_b {
//     display: flex;
//     flex-wrap: wrap;
//     margin-left: -5px;
//     margin-right: -5px
// }

// .cco_menu_b .cco_menu_b_item {
//     padding: 5px
// }

// .cco_menu_b .cco_menu_b_item a {
//     background-color: rgba(223,189,38,.1);
//     border-radius: 5px;
//     font-family: HKGrotesk Medium;
//     font-size: 12px;
//     font-weight: 500;
//     padding: 5px 10px
// }

// .cco_section_a {
//     background-color: #fff;
//     bottom: 0;
//     box-shadow: 0 -10px 30px 0 rgba(0,0,0,.1);
//     display: none;
//     left: 0;
//     padding: 15px 25px 7px;
//     position: fixed;
//     width: 100%
// }

// @media (min-width: 768px) {
//     .cco_section_a {
//         display:none
//     }
// }

// .cco_section_b {
//     margin-left: auto;
//     margin-right: auto;
//     max-width: 1215px;
//     padding: 70px 40px 90px
// }

// @media (max-width: 767px) {
//     .cco_section_b {
//         padding-left:25px;
//         padding-right: 25px
//     }
// }

// .cco_text_a h1 {
//     font-size: 32px;
//     text-align: center
// }

// .cco_text_b h2 {
//     color: #12022f;
//     font-size: 24px
// }

// .cco_text_b_icon_crane {
//     font-feature-settings: normal;
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     align-items: center;
//     display: flex;
//     font-family: Icomoon!important;
//     font-style: normal;
//     font-variant: normal;
//     font-weight: 400;
//     line-height: 1;
//     text-transform: none
// }

// .cco_text_b_icon_crane:before {
//     background-color: #c67b05;
//     border-radius: 5px;
//     color: #fff;
//     content: "\e903";
//     font-size: 24px;
//     margin-right: 10px;
//     padding: 4px
// }

// .cco_text_c h5 {
//     color: #000;
//     padding-bottom: 0
// }

// .cco_text_d p {
//     font-size: 14px
// }

// .cco_text_e {
//     padding-bottom: 5px;
//     padding-top: 5px
// }

// .cco_form_a {
//     margin-left: auto;
//     margin-right: auto;
//     max-width: 600px;
//     position: relative;
//     width: 100%
// }

// .cco_form_a input[type=text] {
//     background-color: #fff;
//     border: 0;
//     border-radius: 15px;
//     color: #666;
//     font-size: 14px;
//     line-height: 1.7;
//     padding: 13px 70px 13px 140px;
//     position: relative;
//     width: 100%
// }

// .cco_form_a input[type=text] ::-moz-placeholder {
//     color: #666;
//     font-size: 14px;
//     line-height: 1.7
// }

// .cco_form_a input[type=text] ::placeholder {
//     color: #666;
//     font-size: 14px;
//     line-height: 1.7
// }

// .cco_form_a select {
//     background-color: #f7f7f7;
//     border: 0;
//     border-radius: 7px;
//     color: #666;
//     font-size: 12px;
//     left: 10px;
//     line-height: 1.3;
//     padding: 6px 12px 6px 8px;
//     width: 115px;
//     z-index: 100
// }

// .cco_form_a select,.cco_form_a_icon_search {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%)
// }

// .cco_form_a_icon_search {
//     background-color: #c67b05;
//     border: 0;
//     border-radius: 10px;
//     color: #fff;
//     cursor: pointer;
//     height: 40px;
//     padding: 5px;
//     right: 5px;
//     width: 44px
// }

// .cco_form_a_icon_search span {
//     display: none
// }

// .cco_form_a_icon_search:before {
//     font-feature-settings: normal;
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #fff;
//     content: "\e914";
//     font-family: Icomoon!important;
//     font-size: 24px;
//     font-style: normal;
//     font-variant: normal;
//     font-weight: 400;
//     left: 50%;
//     line-height: 1;
//     position: absolute;
//     text-transform: none;
//     top: 50%;
//     transform: translate(-50%,-50%)
// }
// /* Overlay styles */
// .modal-overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//     background: rgba(0, 0, 0, 0.5);
//     display: flex;
//     justify-content: center;
//     align-items: flex-end; /* Align modal at the bottom */
//     z-index: 999;
//   }
  
//   /* Modal content styles */
//   .modal-content {
//     background: white;
//     border-radius: 10px 10px 0 0;
//     padding: 20px;
//     width: 100%;
//     max-width: 500px;
//     box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
//     animation: slideUp 0.3s ease-in-out;
//   }
  
//   /* Dropdown menu styles */
//   .dropdown-menu {
//     list-style: none;
//     padding: 0;
//     margin: 0;
//   }
  
//   .dropdown-item {
//     display: flex;
//     align-items: center;
//     padding: 10px 0;
//     cursor: pointer;
//     border-bottom: 1px solid #eaeaea;
//   }
  
//   .dropdown-item:hover {
//     background: #f9f9f9;
//   }
  
//   .flag-icon {
//     height: 20px;
//     width: 20px;
//     margin-right: 10px;
//   }
  
//   .dropdown-text {
//     font-size: 16px;
//     color: #333;
//   }
  
//   /* Slide-up animation */
//   @keyframes slideUp {
//     from {
//       transform: translateY(100%);
//     }
//     to {
//       transform: translateY(0);
//     }
//   }
  
// @media (max-width: 767px) {
//     .cco_form_a input[type=text] {
//         padding-left:25px
//     }

//     .cco_form_a select {
//         background-color: #fff;
//         margin-top: 25px;
//         padding-left: 25px;
//         padding-right: 25px;
//         position: static;
//         width: 100%
//     }

//     .cco_form_a_icon_search {
//         top: 5px;
//         transform: translateY(0)
//     }
// }

// .cco_row_a {
//     margin-bottom: 25px
// }

// .cco_row_b {
//     justify-content: center;
//     margin-bottom: 40px
// }

// .cco_row_b,.cco_row_c {
//     display: flex;
//     flex-wrap: wrap
// }

// .cco_row_d {
//     margin-bottom: 15px
// }

// .cco_col_a:first-child {
//     margin-right: 10px
// }

// .cco_col_b {
//     width: 50%
// }

// .cco_col_b:first-child {
//     padding-right: 32px
// }

// @media (max-width: 767px) {
//     .cco_col_b {
//         width:100%
//     }

//     .cco_col_b:first-child {
//         padding-right: 0
//     }

//     .cco_col_b:nth-last-child(n+2) {
//         margin-bottom: 25px
//     }
// }

// .cco_card_a {
//     background-color: #fff;
//     border-radius: 20px;
//     box-shadow: 0 10px 30px 0 rgba(0,0,0,.05);
//     padding: 25px
// }

// .cco_card_a_header {
//     padding-bottom: 10px
// }

// .cco_card_a:nth-last-child(n+2) {
//     margin-bottom: 25px
// }

// .cco_header {
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     left: 50%;
//     padding-bottom: 25px;
//     padding-top: 30px;
//     position: absolute;
//     top: 0;
//     transform: translateX(-50%);
//     width: 100%;
//     z-index: 100
// }

// .cco_header_container {
//     align-items: center;
//     display: flex;
//     margin-left: auto;
//     margin-right: auto;
//     max-width: 1080px;
//     position: relative;
//     width: 80%
// }

// // .cco_header_logo img {
// //     display: block;
// //     max-height: 65px;
// //     max-width: 65px;
// //     -o-object-fit: contain;
// //     object-fit: contain
// // }

// // @media (max-width: 980px) {
// //     .cco_header_logo img {
// //         max-height:60px;
// //         max-width: 60px
// //     }
// // }

// // @media (max-width: 767px) {
// //     .cco_header_logo img {
// //         max-height:55px;
// //         max-width: 55px
// //     }
// // }

// .cco_header_logo img {
//     display: block; /* Default display for desktop */
//     max-height: 65px;
//     max-width: 65px;
//     -o-object-fit: contain;
//     object-fit: contain;
// }

// @media (max-width: 980px) {
//     .cco_header_logo img {
//         max-height: 60px;
//         max-width: 60px;
//     }
// }

// @media (max-width: 767px) {
//     .cco_header_logo img {
//         display: none; /* Hide logo on mobile screens */
//     }
// }


// .cco_header_nav {
//     flex-grow: 1;
//     padding-left: 40px
// }

// .cco_header_nav_desktop {
//     display: flex
// }

// @media (max-width: 980px) {
//     .cco_header_nav_desktop {
//         display:none
//     }
// }

// @media (min-width: 981px) {
//     .cco_header_nav_mobile {
//         display:none
//     }
// }

// .cco_header_nav_mobile_open .cco_header_list_3 {
//     display: block
// }

// .cco_header_list_1 {
//     display: flex;
//     flex-grow: 1;
//     flex-wrap: wrap;
//     margin-left: -10px;
//     margin-right: -10px;
//     list-style: none;
// }

// .cco_header_list_1_item {
//     padding: 7.25px 10px
// }

// .cco_header_list_1_item a {
//     color: #666;
//     display: block;
//     font-family: DMSans Medium;
//     font-size: 18px;
//     line-height: 1.3;
//     padding: 5px;
//     text-decoration: none;
// }

// .cco_header_list_2 {
//     display: flex;
//     margin-left: -15px;
//     margin-right: -15px;
//     padding-left: 20px;
//     list-style:none;
    
// }

// .cco_header_list_2_item {
//     padding-left: 15px;
//     padding-right: 15px
// }

// .cco_header_list_2_item a {
//     border-radius: 24px;
//     display: block;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     min-width: 170px;
//     padding: 8.5px 30px;
//     text-align: center
// }

// .cco_header_list_2_item_register a {
//     background-color: #c67b05;
//     border: 2px solid #c67b05;
//     color: #fff !important;
//     text-decoration: none;
// }

// .cco_header_list_2_item_sign a {
//     background-color: #fff;
//     border: 2px solid #c67b05;
//     color: #c67b05
// }

// .cco_header_list_3 {
//     background-color: #fff;
//     border-top: 3px solid #c67b05;
//     box-shadow: 0 10px 30px rgba(0,0,0,.05);
//     display: none;
//     left: 0;
//     padding: 4% 5% 5%;
//     position: absolute;
//     top: calc(100% + 25px);
//     width: 100%
// }

// .cco_header_list_3_item:nth-last-child(n+2) {
//     border-bottom: 1px solid rgba(198,123,5,.1)
// }

// .cco_header_list_3_item a {
//     color: #c67b05;
//     display: inline-block;
//     padding: 8px 30px
// }

// .cco_header_list_3_item_register,.cco_header_list_3_item_sign {
//     padding-bottom: 5px;
//     padding-top: 5px
// }

// .cco_header_list_3_item_register a,.cco_header_list_3_item_sign a {
//     border: 1px solid #c67b05;
//     border-radius: 22px;
//     font-family: DMSans Bold;
//     padding-bottom: 5px;
//     padding-top: 5px
// }

// @media (max-width: 767px) {
//     .cco_header_list_3 {
//         padding:5% 5% 7%
//     }
// }

// @media (max-width: 479px) {
//     .cco_header_list_3 {
//         padding:5% 5% 10%
//     }
// }

// .cco_header_list_4 {
//     display: flex;
//     flex-wrap: wrap;
//     margin-left: -10px;
//     margin-right: -10px;
//     padding-top: 15px
// }

// // .cco_header_list_4_item {
// //     padding: 5px 30px
// //     // padding: 4px 2px 4px 5px;

// // }

// .cco_header_list_4_item {
//     // padding: 5px 30px;
//     margin-left: 60px; /* Keep left adjustment */
//     position: relative; /* Enables the use of top for vertical movement */
//     top: -37px;         /* Move it upward */
// }



// .cco_header_list_4_item a {
//     border-radius: 24px;
//     display: block;
//     font-family: DMSans Bold;
//     font-size: 18px;
//     min-width: 170px;
//     padding: 5px 30px;
//     margin-left: 8px;
//     text-align: center
// }



// .cco_header_list_4_item_register a {
//     background-color: #c67b05;
//     border: 2px solid #c67b05;
//     color: #fff
// }

// .cco_header_list_4_item_sign a {
//     background-color: #fff;
//     border: 2px solid #c67b05;
//     color: #c67b05
// }

// @media (max-width: 479px) {
//     .cco_header_list_4_item,.cco_header_list_4_item a {
//         width:100%
//     }
// }

// .cco_header_list_4_item {
//     display: flex; /* Align logo and button horizontally */
//     align-items: center; /* Vertically center the elements */
// }

// // .login-logo {
// //     height: 38px; /* Adjust logo height */
// //     width: auto; /* Maintain aspect ratio */
// //     margin-right: 12px; /* Space between logo and button */
// //     margin-left: 9px;
// //     position: relative; /* Use relative positioning */
// //     left: 62px; /* Move it to the left without affecting siblings */
// // }

// .login-logo {
//     display: flex; /* Flexbox use karein */
//     justify-content: center; /* Horizontally center */
//     align-items: flex-start; /* Top se alignment */
//     height: 5vh; /* Full viewport height */
//     // margin-top: 12px; /* Top se thoda neeche le aane ke liye */
// }



// .login-button {
//     float: left;
//     display: inline-flex; /* Keeps button as small as content */
//     justify-content: flex-start; /* Align content to the left */
//     align-items: center; /* Vertically center the content */
//     text-align: left; /* Align text to the left */
//     text-decoration: none; /* Remove underline */
//     // padding: 4px 2px; /* Reduced padding to make button width smaller */
//     border: 1px solid #c67b05; /* Keep border */
//     background-color: #c67b05; /* Button background */
//     border-radius: 24px; /* Rounded button */
//     color: #fff; /* Text color */
//     font-size: 16px; /* Slightly smaller font */
//     font-family: DMSans, sans-serif; /* Font style */
//     font-weight: bold; /* Bold text */
//     white-space: nowrap; /* Prevent text wrapping */
//     width: auto; /* Let the button take only the necessary width */
// }
// // .three {
// //     background-color: #b8b8b8;
// //     display: flex;
// //     justify-content: space-between;
// //     align-items: center;
// //   }

// /* Default: Hide the component */
// /* Default: Hide the component */
// // .three {
// //     display: none;
// //   }
  
//   /* Mobile View: Show the component */
//   @media (min-width: 320px) and (max-width: 600px) {
//     .three {
//       display: flex;
//     }
//   }
  
  


// /* Dropdown Menu */
// /* Dropdown Menu */
// /* Dropdown Menu */
// .dropdown-menu {
//     position: absolute;
//     z-index: 1000;
//     top: calc(100% + 20px); /* 20px neeche open karne ke liye */
//     left: 0;
//     padding: 0;
//     // margin-top: px;
//     list-style-type: none;
//     background-color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 6px;
//     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
//     width: 180px;
//     overflow: hidden;
//   }
  
  
  
//   /* Dropdown Item */
//   .dropdown-item {
//     display: flex;
//     align-items: center;
//     padding: 10px;
//     cursor: pointer;
//     transition: background-color 0.2s ease;
//     border-bottom: 1px solid #f0f0f0;
//   }
  
//   .dropdown-item:last-child {
//     border-bottom: none;
//   }
  
//   .dropdown-item:hover {
//     background-color: #f5f5f5;
//   }
  
//   /* Flag Icon */
//   .flag-icon {
//     height: 20px;
//     width: 26px;
//     margin-right: 12px;
//     border: 1px solid #ddd;
//   }
  
//   /* Dropdown Text */
//   .dropdown-text {
//     margin: 0;
//     color: #333;
//     font-size: 14px;
//     font-weight: 500;
//   }
  
  






// .cco_header_toggle {
//     background-color: transparent;
//     border: 0;
//     cursor: pointer;
//     display: block;
//     margin-left: auto;
//     padding: 0;
//     transform: rotate(180deg)
// }

// .cco_header_toggle_icon {
//     display: block
// }

// .cco_header_toggle_icon:before {
//     font-feature-settings: normal;
//     speak: never;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #c67b05;
//     content: "\e918";
//     display: block;
//     font-family: Icomoon!important;
//     font-size: 22px;
//     font-style: normal;
//     font-variant: normal;
//     font-weight: 400;
//     line-height: 1;
//     text-transform: none
// }

// @media (max-width: 767px) {
//     .cco_header_toggle_icon:before {
//         font-size:20px
//     }
// }

// .loginPage .cco_header {
//     box-shadow: none !important;
//     max-width: 1360px;
//     padding-left: 4px;
//     // padding-right: 40px;
//     padding-top: 50px
// }

// .loginPage .cco_header_container {
//     max-width: 100%;
//     width: 100%
// }

// .loginPage .cco_header_logo img {
//     content: url('../../src/assets/images/Logo_CCO_1.png');
//     max-height: 80px;
//     max-width: 80px
// }

// .loginPage .cco_header_list_1_item a {
//     color: #fff
// }

// .loginPage .cco_header_list_2_item_sign a {
//     border-color: #fff
// }

// .loginPage .cco_header_list_3 {
//     box-shadow: none
// }

// .loginPage .cco_header_toggle_icon:before {
//     color: #fff
// }

// @media (max-width: 980px) {
//     .loginPage .cco_header {
//         padding-top:40px
//     }

//     .loginPage .cco_header_logo img {
//         max-height: 70px;
//         max-width: 70px
//     }

//     .loginPage .cco_header_list_3 {
//         top: calc(100% + 15px)
//     }
// }

// @media (max-width: 767px) {
//     .loginPage .cco_header {
//         padding-top:30px
//     }

//     .loginPage .cco_header_logo img {
//         max-height: 65px;
//         max-width: 65px
//     }
// }

// @media (max-width: 767px) and (max-width:767px) {
//     .loginPage .cco_header_toggle_icon:before {
//         font-size:22px
//     }
// }

// @media (min-width: 1200px) {
//     .cco_img_a {
//         padding-top:100px
//     }
// }

// .page-template-faq {
//     background-color: #fafafb
// }

// .cco_section_a {
// 	display: none;
// }

// .cco_blurb_2 {
// 	align-items: flex-start;
// }
// .cco_blurb_2 .cco_blurb_title {
// 	display: -webkit-box;
//   -webkit-line-clamp: 4;
//   -webkit-box-orient: vertical;
// 	overflow: hidden;
// }

// @media (min-width: 1200px) {
// 	body #page-container .cco_et_img_0 {
// 		height: calc(100% - 205px);
// 		top: 205px;
// 	}
// }

// @media (max-width: 1199px) {
// 	.cco_et_col_0,
// 	.cco_et_col_1{
// 		width: 100% !important;
// 	}
// }

// .cco_et_text_0 {
// 	font-family: 'DMSans Bold';
// 	font-size: 16px;
// 	line-height: 1.5;
// }

// .cco_et_text_0 a {
// 	color: #fff;
// 	font-size: 18px;
// }
// .cco_et_text_0 a .text {
// 	text-decoration: underline;
// }
// .cco_et_text_0 a[target=_blank]::after {
// 	speak: never;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// 	color: #fff;
// 	content: "\e92a";
// 	font-family: Icomoon;
// 	font-size: 14px;
// 	font-style: normal;
// 	line-height: 1;
// 	margin-left: 4px;
// }

// .cco_et_text_0 ul {
// 	font-family: 'DMSans Bold';
// 	font-size: 20px;
// 	line-height: 1.5;
// 	padding-bottom: 30px;
// }

// ol ul{
//     list-style: none;
// }

// embed, iframe, object, video {
//     max-width: 100%;
// }

// .tpCards a:hover{
//     color: #666;
// }
// .cco_menu_items {
//     display: flex;
   
// }

// .menu-icon {
//     margin-left: 20px;
//     margin-top :2px;
//     width: 24px;
//     height: 24px;
//     fill: #666666; /* Set the initial color for SVG icons */
//     transition: fill 0.3s ease; /* Smooth transition for hover effect */
// }
// .center-text{ text-align: center; } 

// .display-table{ display: table; height: 100%; width: 100%; }

// .display-table-cell{ display: table-cell; vertical-align: middle; }



// ::-webkit-input-placeholder { font-size: .9em; letter-spacing: 1px; }

// ::-moz-placeholder { font-size: .9em; letter-spacing: 1px; }

// :-ms-input-placeholder { font-size: .9em; letter-spacing: 1px; }

// :-moz-placeholder { font-size: .9em; letter-spacing: 1px; }


// .full-height{ height: 100%; }

// .position-static{ position: static; }

// .font-white{ color: #fff; }


// /* ---------------------------------
// 3. MAIN SECTION
// --------------------------------- */

// .main-area{ position: relative; height: 100vh; z-index: 1; padding: 0 20px; background-size: cover; color: #fff; }

// .main-area:after{ content:''; position: absolute; top: 0; bottom: 0;left: 0; right: 0; z-index: -1;  
// 	opacity: .4; background: #000; }

// .main-area .desc{ margin: 20px auto; max-width: 1100px;  font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
//     font-size: 16px;
//     color: white;}
// .title{
//     font-weight:100;
//     font-style: normal;
//     text-transform: none;
//     text-decoration: none;
//     line-height: 1.3em;
//     font-family: 'DM Sans',Helvetica,Arial,Lucida,sans-serif;
//     font-size: 50px;
//     color: white;
//     margin-top: 160px;
// }


// .main-area .social-btn{ position: absolute; bottom: 40px; width: 100%; left: 50%; transform: translateX(-50%); }


// .main-area{
//     background-Image: url(../assets/images/countdown-3-1600x900.png);
//     background-color: rgba(0, 0, 0, 0.5); 
// }
// .img{
//     margin-top: 50px;
//     width: 150px;
//     height: 150px;
// }
// .social-btn {
//     display: flex; /* This makes the list items align in a row */
//     list-style: none; /* Remove default bullet points */
//     padding: 0; /* Remove default padding */
//     margin: 0; 
//     justify-content: center;/* Remove default margin */
    
//     li {
//       margin-right: 15px; /* Space between links */
      
//       a {
//         text-decoration: none;
//         color: white; /* Default link color */
//         font-size: 16px;
  
//         &:hover {
//           color: pink; /* Change color to pink on hover */
//         }
//       }
//     }
//   }
  






                    







