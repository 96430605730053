div.et_pb_section.et_pb_section_0 {
    background-image: linear-gradient(180deg, rgba(223, 189, 38, 0) 0%, rgba(223, 189, 38, 0.57) 100%), url(https://sandbox1995.ccocert.org/wp-content/uploads/2022/09/Background_1.jpg) !important
}

.et_pb_section_0.et_pb_section {
    background-color: #dfbd26 !important
}

.et_pb_image_0 {
    text-align: left;
    margin-left: 0
}

@media only screen and (max-width:980px) {
    .et_pb_image_0 .et_pb_image_wrap img {
        width: auto
    }
}

@media only screen and (max-width:767px) {
    .et_pb_image_0 .et_pb_image_wrap img {
        width: auto
    }
}

.et_builder_inner_content .cco_et_section_0>.et_pb_row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    max-width: 1360px;
    min-height: 100vh;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 150px;
    position: static;
    width: 100%;
}

.et_pb_column, .et_pb_row, .et_pb_row_inner {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.et_pb_row {
    width: 80%;
    max-width: 1080px;
    margin: auto;
    position: relative;
}

